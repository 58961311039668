/* @tailwind base;
@tailwind components;
@tailwind utilities; */
@media (min-width: 992px) {
  .lg-text-7xl {
    font-size: 4.5rem; /* Equivalent to lg:text-7xl */
  }
  .lg-py-20 {
    padding-top: 5rem; /* Equivalent to lg:py-20 */
    padding-bottom: 5rem; /* Equivalent to lg:py-20 */
  }
}


  
    
     
.custom-py-12 {
  padding-top: 3rem; /* Equivalent to py-12 in Tailwind */
  padding-bottom: 3rem; /* Equivalent to py-12 in Tailwind */
}
.text-custom-blue {
  color: #00718F !important; /* Bootstrap primary color */
}
.gap-52 {
  gap: 13rem; /* Equivalent to 208px */
}
.bg-success-blue {
  background-color: #032D42;
}
.bg-success-grey {
  background-color: #EAEBEE;
}
.servicelogo{
  margin-left: -195px
}
.font-primaryhead{
  color: #032D42;
}
.offerhead{
  color: #00718F;
}
.success-offer{
  color: #62D84E;
}
.first-bottom-ban{
  margin-top: 500px
}


@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@100;200;300;400;500;600;700;800;900&display=swap');
body {
  margin: 0;
  font-family: 'Lexend', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
h2{
  font-weight: 600;
  font-size: 40px;
  line-height: 50px;
  color: #211e6d;
  padding-bottom: 15px;

}
p{
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
}

.industry-banner {
  background-image: url("../public/Assets/Frame 128.webp");
  background-repeat: no-repeat;
  background-size: cover;
}

.footer-icon {
  font-size: 2.3em;
  /* padding-right: 0.5em; */
  color: #ffffff;
}

.virticleline{
  border-right: 1px solid #838383;
  
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.servicecardicons {
  width: 100px;
}

.paratag {
  color: black;
}
.paratag1 {
  color: black;
}

.paratag2 {
  color: #fff;
}

.paratag3 {
  color: black;
}

.servicecard {
  background: #ffffff;
  box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
}

#team {
  background-color: #fffbeb;
}

#team3 {
  background-color: #EDECFF;
}

.inputinput {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.bottom_banner {
  background-image: url("../public/Assets/Bottom_Banner.webp");
}

.small-card-mobile {
  display: none;
}

.contactsection1 {
  width: 100%;
  background-image: url(../public/Assets/contact-us-banner.webp);
  background-position: center;
  background-size: cover;
  object-fit: cover;
  background-repeat: no-repeat;
}

.mobilesection1 {
  width: 100%;
  background-image: url(../public/Assets/mobile-app-banner-section.webp);
  background-position: center;
  background-size: cover;
  object-fit: cover;
  background-repeat: no-repeat;
}

.digitalmarketingsection1 {
  width: 100%;
  background-image: url(../public/Assets/Digital_Marketing.webp);
  background-position: center;
  background-size: cover;
  object-fit: cover;
  background-repeat: no-repeat;
}

.graphicssection1 {
  width: 100%;
  background-image: url(../public/Assets/Graphicdesign_banner.webp);
  background-position: center;
  background-size: cover;
  object-fit: cover;
  background-repeat: no-repeat;
}

.emailmarketingsection1 {
  width: 100%;
  background-image: url(../public/Assets/email-marketing-banner.webp);
  background-position: center;
  background-size: cover;
  object-fit: cover;
  background-repeat: no-repeat;
}

.applicationservicessection1 {
  width: 100%;
  background-image: url(../public/Assets/application-services-banner.webp);
  background-position: center;
  background-size: cover;
  object-fit: cover;
  background-repeat: no-repeat;
}

.mernsection1 {
  width: 100%;
  background-image: url(../public/Assets/mernstackbanner.webp);
  background-position: center;
  background-size: cover;
  object-fit: cover;
  background-repeat: no-repeat;
}

.uiuxsection1 {
  width: 100%;
  background-image: url(../public/Assets/ui-ux-banner-section.webp);
  background-position: center;
  background-size: cover;
  object-fit: cover;
  background-repeat: no-repeat;
}
.mobileappdevelopmentsection {
  width: 100%;
  background-image: url(../public/Assets/mobileappdevelopment/banner.png);
  background-position: center;
  background-size: cover;
  object-fit: cover;
  height: 70vh;
  background-repeat: no-repeat;
}
.blockchaindevelopment {
  width: 100%;
  background-image: url(../public/Assets/emerging/blockchain/banner.png);
  background-position: center;
  background-size: cover;
  object-fit: cover;
  height: 70vh;
  background-repeat: no-repeat;
}
.staffing-services {
  width: 100%;
  background-image: url(../public/Assets/staffing/staffing-services/banner.png);
  background-position: center;
  background-size: cover;
  object-fit: cover;
  height: 70vh;
  background-repeat: no-repeat;
}
.recrutiment-services {
  width: 100%;
  background-image: url(../public/Assets/staffing/recrutiment-services/banner.png);
  background-position: center;
  background-size: cover;
  object-fit: cover;
  height: 70vh;
  background-repeat: no-repeat;
}
.wordkday {
  width: 100%;
  background-image: url(../public/Assets/itconsulting/workday/banner.png);
  background-position: center;
  background-size: cover;
  object-fit: cover;
  height: 70vh;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
}
.salesforce {
  width: 100%;
  background-image: url(../public/Assets/itconsulting/salesforce/banner.png);
  background-position: center;
  background-size: cover;
  object-fit: cover;
  height: 70vh;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
}
.webdevelopment {
  width: 100%;
  background-image: url(../public/Assets/services_img/webdevelopment/banner.png);
  background-position: center;
  background-size: cover;
  object-fit: cover;
  height: 70vh;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
}
.servicenow {
  width: 100%;
  background-image: url(../public/Assets/itconsulting/servicenow/banner.png);
  background-position: center;
  background-size: cover;
  object-fit: cover;
  height: 70vh;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
}
.jira {
  width: 100%;
  background-image: url(../public/Assets/itconsulting/jira/banner.png);
  background-position: center;
  background-size: cover;
  object-fit: cover;
  height: 70vh;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
}
.kronos {
  width: 100%;
  background-image: url(../public/Assets/itconsulting/kronos/banner.png);
  background-position: center;
  background-size: cover;
  object-fit: cover;
  height: 70vh;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
}
.automation {
  width: 100%;
  background-image: url(../public/Assets/emerging/automation/banner.png);
  background-position: center;
  background-size: cover;
  object-fit: cover;
  height: 70vh;
  background-repeat: no-repeat;
}
.cognitivecomputing {
  width: 100%;
  background-image: url(../public/Assets/emerging/cognitive/banner.png);
  background-position: center;
  background-size: cover;
  object-fit: cover;
  height: 70vh;
  background-repeat: no-repeat;
}
.extended-reality {
  width: 100%;
  background-image: url(../public/Assets/emerging/extended-reality/banner.png);
  background-position: center;
  background-size: cover;
  object-fit: cover;
  height: 70vh;
  background-repeat: no-repeat;
}
.datasciencebanner {
  width: 100%;
  background-image: url(../public/Assets/emerging/datascience/banner.png);
  background-position: center;
  background-size: cover;
  object-fit: cover;
  height: 70vh;
  background-repeat: no-repeat;
}
.graphicsection1 {
  width: 100%;
  background-image: url(../public/Assets/graphic-design-banner-section.webp);
  background-position: center;
  background-size: cover;
  object-fit: cover;
  background-repeat: no-repeat;
}

.ui-ux-consulting-section {
  width: 100%;
  background-image: url(../public/Assets/ui-ux-consulting-service.webp);
  background-position: center;
  background-size: cover;
  object-fit: cover;
  background-repeat: no-repeat;
}

.webdesignsection1 {
  width: 100%;
  background-image: url(../public/Assets/web-development-banner.webp);
  background-position: center;
  background-size: cover;
  object-fit: cover;
  background-repeat: no-repeat;
}

.salesforcesection1 {
  width: 100%;
  background-image: url(../public/Assets/salesforce-banner.webp);
  background-position: center;
  background-size: cover;
  object-fit: cover;
  background-repeat: no-repeat;
}

.apisection1 {
  background-color: #211E6D;
  /* width: 100%;
  background-image: url(../public/Assets/API-Banner.webp);
  background-position: center;
  background-size: cover;
  object-fit: cover;
  background-repeat: no-repeat; */
}
.mainseves {
  background-color: #211E6D;
  width: 100%;
  height: 70vh;
  background-image: url(../public/Assets/services_img/mainsevebnr.png);
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  color: #fff; 
}
.ecommdevlop{
  background-color: #211E6D;
  width: 100%;
  height: 70vh;
  background-image: url(../public/Assets/services_img/ecommdev/Ecomm_banner.png);
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  color: #fff; 
}
.Enterprisesec{
  background-color: #211E6D;
  width: 100%;
  height: 70vh;
  background-image: url(../public/Assets/services_img/Enterprise/Enterprise_banner.png);
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  color: #fff;
}
.qatestingsection{
  background-color: #211E6D;
  width: 100%;
  height: 70vh;
  background-image: url(../public/Assets/services_img/QA/qa-banner.png);
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  color: #fff;
}
.develpmentser{
  border-radius: 22px;
  background-color: #EDECFF;
  position: relative;
}
.develpmentser img{
  position: absolute;
  right: -300px;
  top: 39px;
  
}
.borderforleft{
  position: relative;
}
.borderforleft::before{
  position: absolute;
  content: "";
  width: 3px;
  height: 100%;
  left: -18px;
  background-color: #EDECFF;

}
.bgdfsxdevelop{
  background-color: #EDECFF;
}
.realitydfx{
  position: relative;
}
.realitydfx .container{
  position: absolute;
  top: 19%;
}
.realitydfx .container .row{
  background-color: #fff;
  width: 350px;
}
.bgcolorforfaq{
  background-color: #211E6D;
}
.hoverdevelop{
  transition: all .3s ease-in;
}

.hoverdevelop:hover{
  background-color: #211E6D;
  color: #fff;

}
.hoverdevelop:hover a{
  color: #fff;
}
.hoverdevelop:hover p{
  color: #fff;
}
.hoverdevelop:hover img{
  filter: invert(100%) sepia(97%) saturate(12%) hue-rotate(137deg) brightness(104%) contrast(105%);
}
.bgdelopsection{
 
  width: 100%;
  background-image: url('../public/Assets/development/sectionbg.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.inquerybotm{
  width: 100%;
  height: 50vh;
  display: flex;
  align-items: center;
  background-image: url('../public/Assets/development/inwuriy.png');
  background-repeat: no-repeat;
  background-size: cover;
}
.csstudy{
  background-color: #EDECFF;
}


.develpmentser a{
  text-decoration: none;
  padding: 13px 40px;
  background-color: #211E6D;
  color: #fff;
  border-radius: 8px;
  font-size: 18px;
  line-height: 23px;
  font-weight: 500;
}
.sevicemanpage a{
  text-decoration: none;
  padding: 13px 40px;
  background-color: #211E6D;
  color: #fff;
  border-radius: 8px;
  font-size: 18px;
  line-height: 23px;
  font-weight: 500;
}
.sevicemanpage a:hover{
 
  background-color: #211E6D;
  color: #fff;
  
}

.inquerybotm a{
  text-decoration: none;
  padding: 13px 40px;
  border: 1px solid #fff;
  background-color:rgba(255, 255, 255, 0.2);
  color: #fff;
  border-radius: 8px;
  font-size: 18px;
  line-height: 23px;
  font-weight: 500;
}
.inquerybotm a:hover{
  border: 1px solid #fff;
  background-color:rgba(255, 255, 255, 0.2);
  color: #fff;
}
.sevebutton a{
  text-decoration: none;
  padding: 13px 40px;
  border: 1px solid #fff;
  background-color:rgba(255, 255, 255, 0.2);
  color: #fff;
  border-radius: 8px;
  font-size: 18px;
  line-height: 23px;
  font-weight: 500;
}
.sevebutton a:hover{
  border: 1px solid #fff;
  background-color:rgba(255, 255, 255, 0.2);
  color: #fff;
}
.develpmentser a:hover{
  background-color: #211E6D;
  color: #fff;
}
.develpmentser a:active{
  background-color: #211E6D!important;
  color: #fff!important;
  border-color: #211E6D!important;
}

.digitaltransformationsection1 {
  width: 100%;
  background-image: url(../public/Assets/digital-transformation-banner.webp);
  background-position: center;
  background-size: cover;
  object-fit: cover;
  background-repeat: no-repeat;
}

.insightssection1 {
  width: 100%;
  background-image: url(../public/Assets/insights-banner.webp);
  background-position: center;
  background-size: cover;
  object-fit: cover;
  background-repeat: no-repeat;
}

.devOpssection1 {
  width: 100%;
  background-image: url(../public/Assets/devops-banner.webp);
  background-position: center;
  background-size: cover;
  object-fit: cover;
  background-repeat: no-repeat;
}

.healthcaresection1 {
  width: 100%;
  background-image: url(../public/Assets/healthcare-banner.webp);
  background-position: center;
  background-size: cover;
  object-fit: cover;
  background-repeat: no-repeat;
}

.blockchainsection1 {
  width: 100%;
  background-image: url(../public/Assets/blockchain-banner.webp);
  background-position: center;
  background-size: cover;
  object-fit: cover;
  background-repeat: no-repeat;
}

.socialmediamarketingsection1 {
  width: 100%;
  background-image: url(../public/Assets/smm-banner.webp);
  background-position: center;
  background-size: cover;
  object-fit: cover;
  background-repeat: no-repeat;
}

.contentmarketingsection1 {
  width: 100%;
  background-image: url(../public/Assets/Content-marketing-banner.webp);
  background-position: center;
  background-size: cover;
  object-fit: cover;
  background-repeat: no-repeat;
}

.content-ul-section {
  font-weight: 400;
  font-size: 24px;
  line-height: 193.5%;
}

.paragraphHeading {
  font-weight: 400;
  font-size: 18px;
  line-height: 130%;
}

.SMMPackageHeading {
  font-weight: 400;
  font-size: 46px;
  line-height: 30px;
}

.cloudsection1 {
  width: 100%;
  background-image: url(../public/Assets/Cloud-Banner.webp);
  background-position: center;
  background-size: cover;
  object-fit: cover;
  background-repeat: no-repeat;
}

.aisection1 {
  /* width: 100%; */
  background-color: #211E6D;
  /* background-image: url(../public/Assets/AI-banner.webp); */
  /* background-position: center;
  background-size: cover;
  object-fit: cover;
  background-repeat: no-repeat; */
}

.educationsection1 {
  width: 100%;
  background-image: url(../public/Assets/Education-banner-section.webp);
  background-position: center;
  background-size: cover;
  object-fit: cover;
  background-repeat: no-repeat;
}

.hightechnologysection1 {
  width: 100%;
  background-image: url(../public/Assets/Hightechnologybanner.webp);
  background-position: center;
  background-size: cover;
  object-fit: cover;
  background-repeat: no-repeat;
}

.retailsection1 {
  width: 100%;
  background-image: url(../public/Assets/image123.webp);
  background-position: center;
  background-size: cover;
  object-fit: cover;
  background-repeat: no-repeat;
}

.automotivesection1 {
  width: 100%;
  background-image: url(../public/Assets/image126.webp);
  background-position: center;
  background-size: cover;
  object-fit: cover;
  background-repeat: no-repeat;
}

.workdaysection1 {
  width: 100%;
  background-image: url(../public/Assets/workday-banner.webp);
  background-position: center;
  background-size: cover;
  object-fit: cover;
  background-repeat: no-repeat;
}

.servicenowsection1 {
  width: 100%;
  background-image: url(../public/Assets/service-now-banner.webp);
  background-position: center;
  background-size: cover;
  object-fit: cover;
  background-repeat: no-repeat;
}

.consultingsection1 {
  width: 100%;
  background-image: url(../public/Assets/consulting-banner.webp);
  background-position: center;
  background-size: cover;
  object-fit: cover;
  background-repeat: no-repeat;
}

.kronossection1 {
  width: 100%;
  background-image: url(../public/Assets/kronos-banner.webp);
  background-position: center;
  background-size: cover;
  object-fit: cover;
  background-repeat: no-repeat;
}

.contactfirstsection {
  background-color: #f5f5f5;
}

.industry-banner {
  background-image: url("../public/Assets/Frame 128.webp");
  background-repeat: no-repeat;
  background-size: cover;
}

.industry-technology {
  width: 100%;
  background-image: url("../public/Assets/Industry-Technology.webp");
  background-position: center;
  background-size: cover;
  object-fit: cover;
  background-repeat: no-repeat;
}

.industry-link {
  border: 1px solid white;
  padding: 10px 25px;
  margin-bottom: 5em;
  margin-right: 20px;
  border-radius: 30px;
  color: white;
  text-decoration: none;
}



/* -----new code of Experties----- */

.benifit button.active {
  font-size: 24px;
  background-color: #12171a;
  border-bottom: 2px solid #ffffff59;
  color: #ffffff;
  font-weight: 600;
}

.clients-section {
  padding-left: 160px;
}
.ourservicestabs{
  border-radius: 16px;
}
.ourseimgtab img{
  border-radius: 16px 16px 0 0;
}
.tabsinsideh4 h4{
  color: #211E6D;
}
.discriptabs{
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  color: #000000;
  width: 560px;
}
.discriptabs1{
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  color: #ffffff;
  /* width: 560px; */
}
.ulfortab{
  color: #ffffff;
}
/* ------ end section----- */
/* 
.benifit {
  width: 100%;
  background-image: url(../public/Assets/tabsbgimg.webp);
  background-position: center;
  background-size: cover;
  object-fit: cover;
  background-repeat: no-repeat;
} */


.nav-link2 {
  width: 100%;
  display: block;
  border: none;
  padding: 60px 0 10px 0;
  border-bottom: 1px solid #667085;
  text-align: left;
  color: #ffffff;
  background-color: #12171a;
  font-weight: 300;
  transition: all 0.1s linear;
  font-size: 18px;
}

.benifit .active1 {
  background-color: #fff;
  border-left: 3px solid #211e6d;
  color: #000;
}

/* .improve_effici {
  border-top: 1px solid #939393;
} */

.nav-link2:focus {
  background-color: #12171a !important;
  border-bottom: 2px solid #ffffff;
  color: #ffffff !important;
}

/* .nav-link2:hover {
  background-color: #fff;
  border-left: 3px solid #211e6d;
  color: #000;
} */



.mobile_color {
  display: none;
}


.copyright h5 {
  font-weight: 200;
  font-size: 14px;
}




.wordpresssection1 {
  width: 100%;
  background-image: url(../public/Assets/Wordpress.jpg);
  background-position: center;
  background-size: cover;
  object-fit: cover;
  background-repeat: no-repeat;
}

.ecommercesection1 {
  width: 100%;
  background-image: url(../public/Assets/ecommerce-banner-section.webp);
  background-position: center;
  background-size: cover;
  object-fit: cover;
  background-repeat: no-repeat;
}

.customdevelopmentsection1 {
  width: 100%;
  background-image: url(../public/Assets/custom-web-development-banner.webp);
  background-position: center;
  background-size: cover;
  object-fit: cover;
  background-repeat: no-repeat;
}

.cmssection1 {
  width: 100%;
  background-image: url(../public/Assets/CMS-banner.webp);
  background-position: center;
  background-size: cover;
  object-fit: cover;
  background-repeat: no-repeat;
}

.recruitmentsection1 {
  width: 100%;
  background-image: url(../public/Assets/RecruitmentService.webp);
  background-position: center;
  background-size: cover;
  object-fit: cover;
  background-repeat: no-repeat;
}

.staffaugementationsection1 {
  width: 100%;
  background-image: url(../public/Assets/Staff.webp);
  background-position: center;
  background-size: cover;
  object-fit: cover;
  background-repeat: no-repeat;
}

.phpsection1 {
  width: 100%;
  background-image: url(../public/Assets/phpBanner.webp);
  background-position: center;
  background-size: cover;
  object-fit: cover;
  background-repeat: no-repeat;
}





/* new css by p */
.start-parent p {
  text-transform: uppercase;
}

.home-banner {
  width: 100%;
}

.starts {
  font-size: 18px;
}

.starts i {
  margin: 5px;
}

.review span {
  line-height: 0.5;
}

.borderr {
  border-left: 1px solid #656565;
}

.home-heading1 {
  margin-bottom: 0;
  font-size: 64px;
  font-weight: bold;
  line-height: 0.7;
}

.home-heading2 {
  font-size: 64px;
  font-weight: 100;
}

#company1 {
  display: none;
}

/* Card Carousel */
.top-btn {
  background-color: #211e6d;

  font-size: 1.7rem;
  width: 3rem;
  height: 3rem;
  color: #ffffff;

  border-radius: 50%;
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

@keyframes gototop {
  0% {
    transform: translateY(-0.5rem);
  }

  100% {
    transform: translateY(1rem);
  }
}

.dfx-advatege {
  font-size: 56px;
  font-weight: 600;
}

.popular-button {
  /* background-image: #211E6D; */
  text-align: left;
  color: #fff;
  background-color: #211e6d;
}

/* .popular-button:hover {
  color: #fff;
} */

#industries h2 {
  font-weight: 200;
  color: #211e6d;
}

.company-logo {
  margin-top: -400px;
  background: #fff;
  box-shadow: 0px 4px 29px rgba(0, 0, 0, 0.25);
  border-radius: 24px;
}

.client-bg-color {
  background-color: #fffbeb;
}

/* grid */
.clientmain {
  display: -ms-flexbox;
  /* IE10 */
  display: flex;
  -ms-flex-wrap: wrap;
  /* IE10 */
  flex-wrap: wrap;
  padding: 0 4px;
}

/* Create four equal columns that sits next to each other */
.clientmain .column {
  /* -ms-flex: 25%; */
  /* IE10 */
  /* flex: 25%; */
  /* max-width: 25%; */
  /* padding: 0 4px; */
  -ms-flex: 20%;
  /* IE10 */
  flex: 20%;
  max-width: 25%;
  padding: 0 4px;
}

.clientmain .column img {
  margin: 15px;
  vertical-align: middle;
  width: 85%;
}

.happy-client {
  width: 100%;
  /* max-width: 100px; */
  height: auto;
  object-fit: cover;
}

/* Responsive layout - makes a two column-layout instead of four columns */

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 576px) {
  .clientmain .column {
    -ms-flex: 50%!important;
    flex: 50%!important;
    max-width: 50%!important;
  }

  .clientmain-section {
    display: none;
  }

  .last-column {
    display: flex;
    flex-wrap: wrap;
  }

  .home-heading1,
  .home-heading2 {
    font-size: 40px;
  }

  .dfx-advatege {
    font-size: 36px;
  }

  /* .grat-company h2 {
    font-size: 24px;
  } */
}

/* ----------------web design and development---------- */

/* ----------top banner section start-------- */
.banner {
  padding: 120px 15px;
  
}

.banner > h1 {
  font-weight: 600;
  font-size: 58px;
  line-height: 73px;
  margin-bottom: 0;
}

.banner > p {
  font-weight: 400;
  font-size: 22px;
  line-height: 160%;
  margin-bottom: 0;
}

.carousel-caption h1 {
  font-weight: 300;
}



/* ----------top banner section end-------- */

/* ----------Overview section start-------- */

.overviewsection {
  /* padding: 50px 0px 50px 25px; */
  padding-bottom: 60px;
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 189%;
  /* or 42px */
  color: #161616;
}

.overview-section h2 {
  font-size: 40px;
  font-weight: 500;
  line-height: 160%;
}

.overview-section p {
  font-size: 18px;
  font-weight: 400;
  line-height: 180%;
}

.kronos-value-section h2 {
  font-size: 36px;
  font-weight: 600;
  line-height: 140%;
}

.kronos-value-section p {
  font-size: 18px;
  font-weight: 400;
  line-height: 180%;
}

.implementation-heading-section {
  margin-top: 15px;
  font-size: 13px;
  line-height: 19px;
  font-weight: 500;
  margin-bottom: 0px;
  width: 100%;
}

.workforcesection {
  width: 100%;
  background-image: url(../public/Assets/Rectangle30.webp);
  background-position: center;
  background-size: cover;
  object-fit: cover;
  background-repeat: no-repeat;
}

.capabilitiessection {
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 189%;
  color: #161616;
  padding-left: 0px;
}

.bannerbtn {
  padding: 16px 32px;
  background: #ffffff;
  border-radius: 8px;
  color: #0c0265;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
}

/* ----- bottom banner ----- */
.contact_banner {
  width: 100%;
  background-image: url(../public/Assets/banner\ last.webp);
  background-position: center;
  background-size: cover;
  object-fit: cover;
  background-repeat: no-repeat;
  background-color: rgba(33, 30, 109, 0.3);
  background-blend-mode: overlay;
}

.btnexplor12 {
  font-weight: 600;
  font-size: 24px;
  background: #ffffff;
  border-radius: 8px;
  padding: 16px 32px;
  color: #0c0265;
}

.btnexplor12:hover {
  background: #ffffff;
  color: #0c0265;
}

.fontsizeexplor12 {
  font-weight: 600;
  font-size: 48px;
  /* line-height: 60px; */
  color: #ffffff;
}

.fontsizeexplor123 {
  font-weight: 600;
  font-size: 38px;
  /* line-height: 60px; */
  color: #ffffff;
}





.why-dwellfox > h2 {
  font-weight: 600;
  font-size: 40px;
  line-height: 50px;
  color: #211e6d;
  padding-bottom: 15px;
}

.why-dwellfox > p {
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  padding-bottom: 2em;
}

.card-body > h4 {
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  color: #211e6d;
  /* padding-top: 24px; */
}

.card-span {
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 16px !important;
  padding-bottom: 12px;
}

.card-p {
  font-weight: 236 !important;
  font-size: 24px !important;
  line-height: 160% !important;
  padding-top: 12px !important;
}

.card-p1 {
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 18px!important;
  /* padding-top: 12px !important; */
}

/* .why-dwellfox-1 {
  padding: 126px 0px;
  padding-right: 100px;
  padding-left: 101px;
} */

.abt-button {
  border-radius: 8px;
  padding: 12px 44px;
  color: #fff;
  background-color: #211e6d;
  text-decoration: none;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  
  transition: all .15s ease-in-out;
   cursor: pointer;
    border-radius: 10px;
    display: inline-block;
    overflow: hidden;

}
.abt-button::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.button-four {
  /* background: #fff; */
  transform: perspective(1px) translateZ(0);
  /* color: #000; */
  transition: .4s;
}
.button-four:hover::before {
  clip-path: inset(0 0 0 0);
}
.button-four::before {
  background: #2b86aa;
  clip-path: inset(0 100% 0 0);
  opacity: 1;
  transition: .4s;
}

.abt-button:hover{
  color: #fff;
  font-weight: 400;
  background-color: #fffbeb;
  /* box-shadow: 0 0 5px 0 #211e6d inset, 0 0 10px 2px #211e6d; */
  /* border: 1px solid #211e6d; */
}
.abt-button i{
  color: #fff;
visibility: hidden;
transition: all .15s ease-in-out;
margin-left: -10px;
}
.abt-button:hover i{
 visibility: visible;
 margin-left: 0;
}
  


.submit-button {
  border-radius: 8px;
  padding: 12px 80px;
  color: #fff;
  background-color: #211e6d;
  text-decoration: none;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}

.form-control:focus {
  border-color: #ced4da;
  /* default B5 color or set your own color*/
  outline: none !important;
  box-shadow: none !important;
}



.Solutions > h2 {
  font-weight: 600;
  font-size: 46px;
  line-height: 58px;
  color: #211e6d;
  padding-top: 100px;
}

.Solutions > p {
  font-weight: 214;
  font-size: 24px;
  line-height: 30px;
  text-align: center;

}

.cards {
  /* padding: 0% 138px; */
  padding-bottom: 112px;
}

.card-body > h3 {
  font-weight: 200;
  font-size: 25px;
  line-height: 35px;
}

.card-body > p {
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
}

.card-body > span {
  font-weight: 300;
  font-size: 15px;
  line-height: 162%;
}

.staffpad {
  padding-bottom: 33px;
}

.Solbtn {
  border-radius: 8px;
  /* padding: 12px 54px; */
  color: #fff;
  background-color: #211e6d;
  text-decoration: none;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}

.Solbtn:hover {
  color: #fff;
  background-color: #211e6d;
}

@media screen and (max-width: 480px) {
  .cards {
    padding: 1em;
  }



  .carousel-indicators {
    display: none !important;
  }
}

.dfxbanner {
  
  width: 100%;
  background-image: url("./Components/Home/dfx.webp");
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  object-fit: cover;
  background-repeat: no-repeat;
  padding: 100px 0;
  /* padding-left: 258px; */
}

.Advantage > h2 {
  font-weight: 600;
  font-size: 40px;
  line-height: 50px;
  color: #fff;
}

.Advantage > h5 {
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
}

.Advantage p {
  font-weight: 300;

}

@media screen and (max-width: 480px) {
  .dfxbanner {
    padding: 1em;
  }
}

/* .grat-company h2 {
  font-weight: 600;
  font-size: 46px;
  line-height: 58px;
  padding-top: 100px;
  color: white;
  padding-bottom: 32px;
} */

.grat-company {
  width: 100%;
  min-height: 519px;
  background-image: url("../public/Assets/home/gradcompany.png");
  background-position: inherit;

  background-size: cover;
  /* object-fit: cover; */
  background-repeat: no-repeat;
}
.client-img{
  filter: grayscale(100%);
}
.client-img:hover{
  filter: grayscale(0%);
  box-shadow: 1px 5px 27px 0 rgba(0, 0, 0, 0.25) ;
  border-radius: 14.94px;
}

.grat-company p {
  font-weight: 500;
}



.home-section1 {
  padding-top: 70px;
  /* padding-left: 110px; */
}

.home-section1 > h1 {
  font-weight: 700;
  font-size: 64px;
  line-height: 110%;
}

.home-section1 > h3 {
  font-weight: 100;
  font-size: 64px;
  line-height: 110%;
}

.home-section1 > p {
  font-weight: 200;
  font-size: 22px;
  line-height: 33px;
  padding-right: 175px;
  padding-top: 34px;
}

.mainbtn {
  padding-bottom: 35px;
}

@media screen and (max-width: 480px) {
  .home-section1 {
    /* padding-top: 2em; */
    padding-left: 1em;
  }

  .home-section1 > h1 {
    font-weight: 700;
    font-size: 32px;
    line-height: 110%;
  }

  .home-section1 > h3 {
    font-weight: 100;
    font-size: 32px;
    line-height: 110%;
  }

  .home-section1 > p {
    font-weight: 200;
    font-size: 14px;
    line-height: 33px;
    padding-right: 1em;
    padding-top: 34px;
  }

  .mainbtn {
    padding-bottom: 15px;
  }
}


/* ----- footer css ---- */
.policy-heading{
  font-size: 58px;
  font-weight: 700;

}

table, td, th {
  border: 1px solid black;
  text-align: center;
}

table {
  border-collapse: collapse;
  width: 100%;
  
}

td {
  text-align: center;
}
.policy-btn{
  text-decoration: none;
  color: #004dff;
  font-size: 16px;
  font-weight: 600;
  display: inline-block;
  position: relative;
}
.policy-btn:after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: 
  #004dff;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.policy-btn:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}
.footer-cont{
  text-decoration: none;
  font-weight: 400;
font-size: 16px;
line-height: 20px;
color: #FFFFFF;
}
.footer-cont:hover{
  color: #FFFFFF;
}
.footerlast ul{
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
}
.footerlast li{
  display: inline-block;
  margin: 10px;
}
.footerlast li a{
  text-decoration: none;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
}

.footercol > h3 {
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  padding-bottom: 24px;
}

.footercol > ul > li {
  font-weight: 400;
  font-size: 16px;
  line-height: 208%;
}

/* .footer-industry>ul>Link>li {
  font-weight: 400;
  font-size: 16px;
  line-height: 208%;
}

.footer-industry>h3 {
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  padding-bottom: 24px;
} */

.footerlogo {
  padding-bottom: 58.5px;
}
.footer-hr{
  width: 80%;
  background-color: rgba(0, 0, 0, 1);
}
.footercol-1 > p {
  font-weight: 400;
  font-size: 16px;
  line-height: 208%;
  padding-bottom: 46px;
}

.footercol-1 > h2 {
  font-weight: 600;
  font-size: 24px;
  line-height: 130.02%;
}

.copyright {
  border-top: 1px #8d8d8d solid;
  padding-top: 34px;
  padding-bottom: 49px;
  padding-left: 82px;
}

.copyright-bottom > h5 {
  font-weight: 600;
  font-size: 12px;
  line-height: 208%;
  color: #8d8d8d;
  padding-right: 24px;
}

@media screen and (max-width: 480px) {
  .copyright {
    border-top: 1px #8d8d8d solid;
    padding-top: 34px;
    padding-bottom: 49px;
    padding-left: 1em;
  }
}

/* all_center_footer */

.terms_modal .modal-content {
  background: linear-gradient(
    rgba(225, 231, 240, 1) 0%,
    rgba(249, 250, 251, 1) 100%
  );
}

.terms_modal .modal-dialog {
  max-width: 800px;
}

.terms_modal .modal-dialog .policy_wrap {
  max-height: 400px;
  overflow-x: hidden;
  overflow-y: scroll;
  scrollbar-color: grey white;
  scrollbar-width: thin;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* new css */
#developer {
  background-color: #000;
}

#developer video {
  width: 100%;
  height: 93vh;
  object-fit: cover;
}

.video_overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: blue;
  background-blend-mode: overlay;
  opacity: 0.6;
  background-color: transparent;
  background-image: linear-gradient(transparent, #000000 66%);
}

@media screen and (max-width: 576px) {
  .linkbtn {
    padding-left: 7px;
    padding-right: 7px;
  }

  .contact-banner-web-section {
    background-color: black;
    height: 86px;
  }

  .home-section1 {
    padding-top: 40px;
  }

  .card-span {
    font-weight: 600 !important;
    font-size: 20px !important;
    line-height: 16px !important;
    padding-bottom: 12px;
  }

  .card-p {
    font-weight: 236 !important;
    font-size: 20px !important;
    line-height: 160% !important;
    padding-top: 12px !important;
  }

  .healthcare-technology {
    height: auto;
  }

  .frameworksection {
    margin-left: 0px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .cms-website-section h2 {
    font-weight: 600;
    font-size: 28px;
    line-height: 25px;
    color: #000000;
    text-align: center;
  }

  .cms-website-section p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #000000;
  }

  .cms-heading {
    font-weight: 500 !important;
    font-size: 32px !important;
    line-height: 40px !important;
  }

  .cms-paragraph-heading {
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 30px !important;
  }

  .cms-development-section2 h2 {
    font-weight: 600;
    font-size: 28px !important;
    line-height: 130%;
  }

  .section3-heading {
    font-weight: 600 !important;
    font-size: 38px !important;
    line-height: 130% !important;
  }

  .section6-heading {
    font-weight: 600 !important;
    font-size: 30px !important;
    line-height: 40px !important;
  }

  .cms-website-section h2 {
    font-weight: 600 !important;
    font-size: 20px !important;
    line-height: 20px !important;
  }

  .cms-website-section p {
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 24px !important;
  }

  .digital-transformation-section span {
    font-weight: 600 !important;
    font-size: 20px !important;
    line-height: 35px !important;
  }

  .cms-specialize-heading {
    font-weight: 600 !important;
    font-size: 30px !important;
    line-height: 30px !important;
    color: #ffffff !important;
  }

  .cms-ul-li-section ul li {
    font-weight: 600 !important;
    font-size: 18px !important;
    line-height: 180% !important;
    color: #ffffff !important;
  }

  .section5-heading {
    font-weight: 700;
    font-size: 28px !important;
    line-height: 30px !important;
    text-align: center;
    color: #211e6d;
  }

  .section4-heading {
    font-weight: 700;
    font-size: 28px !important;
    line-height: 30px !important;
    text-align: center;
    color: #000000;
  }

  .gallery-heading-section {
    font-weight: 600 !important;
    font-size: 30px !important;
    line-height: 30px !important;
    color: #ffffff;
  }

  .slick-next {
    display: none !important;
  }

  .slick-prev {
    display: none !important;
  }

  .web-development-heading {
    font-weight: 400 !important;
    font-size: 18px !important;
    line-height: 189% !important;
    color: #161616 !important;
  }
}

.capabilitessection1 {
  margin-left: -100px;
}



.capabilitessection2 {
  margin-right: -130px;
}


.mobileheadingfs {
  color: #000;
}

.mobileparafs {
  color: #000;
}

/* .buttonSection>p {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
} */



@media (max-width: 576px) {
  .homebanner {
    width: 375px;
  }

  .pic > img {
    width: 5em;
  }

  .ourusp {
    width: 100%;
  }

  .small-card {
    display: none;
  }

  .small-card-mobile {
    display: block;
  }

  .contacthomebanner {
    background-image: url("../public/Assets/contactus_banner.webp");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
  }

  .industryContent {
    order: 2;
  }

  .industryImage {
    order: 1;
  }

  .clients-section {
    padding-left: 20px;
  }

  .technology-div h5 {
    font-weight: 600;
    font-size: 12px;
    line-height: 28px;
  }

  .ecommerce-heading h2 {
    font-weight: 600 !important;
    font-size: 28px !important;
    line-height: 40px !important;
  }
}



.capablesection {
  background: linear-gradient(
    152.97deg,
    rgba(238, 238, 238, 0.23) 0%,
    rgba(238, 238, 238, 0.135) 100%
  );
  backdrop-filter: blur(34.7503px);
  /* border-radius: 9.94286px; */
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.healthcare-technology1 {
  height: 644px;
}

@media screen and (max-width: 576px) {
  .company-logo{
    margin-top: -221px!important;
  }
  .capablesection {
    background: linear-gradient(
      152.97deg,
      rgba(238, 238, 238, 0.23) 0%,
      rgba(238, 238, 238, 0.135) 100%
    );
    backdrop-filter: blur(0px);
    /* border-radius: 0px; */
    padding-left: 0px;
    padding-right: 0px;
    margin-left: 0px;
    margin-right: 0px;
  }

  .mainhadingcustom {
    position: absolute !important;
    left: 50% !important;
    top: 15% !important;
    transform: translateX(-50%) !important;
    z-index: 1 !important;
    width: 90% !important;
  }

  .custom-development-service {
    width: 100% !important;
    height: 60vh !important;
  }

  .custom-development-section {
    background: #f1f5f9;
    box-shadow: 0px 8px 16px rgb(0 0 0 / 16%);
    margin-top: -150px;
    left: 0% !important;
    position: absolute;
  }
}




.card-industry > h4 {
  font-size: 16px;
  text-transform: uppercase;
}

.careerbanner {
  width: 100%;
  background-image: url(../public/Assets/CAREER_BANNER.webp);
  background-position: center;
  background-size: cover;
  object-fit: cover;
  background-repeat: no-repeat;
}

.industrySectionButton::before,
.industrySectionButton::after {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.industrySectionButton::before {
  background-image: linear-gradient(
    45deg,
    #37d5d6 0%,
    #36096d 75%,
    peachpuff 100%
  );
  /* background-image: linear-gradient(45deg, #000000 25%, #04619F 75%); */
  z-index: -1;
  transition: opacity 240ms linear;
  opacity: 0;
}

.industrySectionButton::after {
  content: "";
  height: 150%;
  width: 0.25em;
  background-color: #fff;
  color: white;
  box-shadow: 0px 0px 8px 4px #fff;
  transform: translateX(-1em);
}

.industrySectionButton:focus {
  color: #000;
}

.industrySectionButton:hover {
  color: #000;
}

.industrySectionButton:hover::before {
  opacity: 1;
}

.industrySectionButton:hover::after {
  animation-duration: 300ms;
  animation-name: sweep;
  color: #000;
}

@keyframes sweep {
  from {
    transform: translateX(-1em);
  }

  to {
    transform: translateX(12.5em);
    animation-timing-function: ease-out;
  }
}

.homeSectionButton {
  padding: 0.75em 1.5em;
  border: 2px solid;
  font-size: larger;
  border-radius: 1.65em;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.homeSectionButton::before,
.homeSectionButton::after {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.homeSectionButton::before {
  background-image: linear-gradient(45deg, #000000 25%, #04619f 75%);
  z-index: -1;
  transition: opacity 240ms linear;
  opacity: 0;
}
#whyDwellfox{
  width: 100%;
  background-image: url('../public/Assets/home/whydfxhomimg.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  object-fit: cover;

 }

.homeSectionButton::after {
  content: "";
  height: 150%;
  width: 0.25em;
  background-color: #fff;
  box-shadow: 0px 0px 8px 4px #fff;
  transform: translateX(-1em);
}

.homeSectionButton:hover::before {
  opacity: 1;
}

.homeSectionButton:hover::after {
  animation-duration: 300ms;
  animation-name: sweep;
}

.img-size {
  width: 171px;
}
.img-size1 {
  width: 145px;
}

/* ppc page css pravin  */
.ppspage_heading {
  font-weight: 600;
  font-size: 36px;
  line-height: 160%;
}

.pccpain {
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 160%;
  /* or 51px */

  text-align: center;
}

.ppborder {
  border: 1px solid #211e6d;
  border-radius: 4px;
}

.ppc_pragraph {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 160%;
}

.ppcseo_bg_col0r {
  background-color: #fffbeb;
}

.ppcseocard {
  width: 100%;
  height: 180px;
  background: #ffffff;
  box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

.ppcseo_bg_disadwantege {
  color: #fff;
  background-color: #211e6d;
}

.disasvofseo_h4 {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 160%;
}

.disasvofseo_h4_p {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 166%;
}

.ppc_sercices {
  color: #211e6d;
}

.borderoundedppcpa {
  background: #ffffff;
  box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

.blankdivheight {
  width: 100%;
  min-height: 364px !important;
  background-color: #fff;
  border-radius: 4px;
}

.headersideimg {
  /* background-color: red; */
  width: 100%;
  height: 340px;
}

.headersideimg video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.ppcmanagementpara {
  text-align: center;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 160%;
}

.straygimg {
  /* background-color: red; */
  height: 292px;
}

.stratgey h5 {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: #fff;
}

.stratgey p {
  font-weight: 400;
  font-size: 14px;
  line-height: 23px;
  color: #fff;
}

/* acordian */
.accordion-button {
  font-style: normal;
  /* font-weight: 600; */
  font-size: 18px;
  line-height: 28px;
  color: #101828;
}
.my-ull li{
  font-family: 'Lexend';
font-style: normal;
  font-weight: 400;
font-size: 14px;
line-height: 134%;
color: #000000;
}
.my-btnn::after{
display: none !important;
}
.accordion-body {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #667085;
}

.accordion-button:focus {
  z-index: 3;
  border-color: transparent;
  outline: 0;
  box-shadow: none;
}

.accordion-button:not(.collapsed) {
  color: #101828;
  font-weight: 600;
  background-color: transparent;
  box-shadow: none;
}

.active:after {
  /* content: "\2212"; */
  transition: all 0.4s linear;
}

.accordion-button:not(.collapsed)::after {
  color: #101828;
  content: "-";
  background-image: none;

}


.accordion-button::after {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #211E6D;
  border-radius: 50%;
  content: "+";
  background-image: none;
}

.accordion {
  --bs-accordion-bg: transparent;
}

/* price list */
.pricelist h6 {
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 30px;
  /* identical to box height */
  /* Neutral / 800 */
  color: #191d23;
}

.pricelist p {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height */
  /* Neutral / 500 */
  color: #64748b;
}

.mainprice {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  /* Neutral / 800 */
  color: #191d23;
}

.mainprice1 {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height */
  /* Neutral / 600 */
  color: #4b5768;
}

.pricebutton button {
  border: 1.5px solid #211e6d;
  border-radius: 4px;
  padding: 6px 12px;
  color: #211e6d;
}

.pricebutton button:hover {
  border: 1.5px solid #211e6d;
}

.list-style-noneprice {
  list-style: none;
}

.list-style-noneprice li {
  padding: 10px 0;
}

.colrlistclose {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height */
  /* Neutral / 400 */
  color: #a0abbb;
}

.baclist {
  background: #211e6d;
  box-shadow: 0px 10px 25px #ccd9ff;
  border-radius: 12px;
}

.baclist {
  color: white;
}

.baclist h6,
.baclist p {
  color: #fff;
}

.baclist button {
  border: none;
  border-radius: 4px;
  padding: 6px 12px;
  color: #211e6d;
  background-color: #fff;
}

.baclist button:hover {
  border: none;
  border-radius: 4px;
  padding: 6px 12px;
  color: #211e6d;
  background-color: #fff;
}

.industry-banner-section {
  padding: 120px 0px;
  padding-left: 51px;
}

.industry-banner-section > h1 {
  font-weight: 600;
  font-size: 56px;
  line-height: 71px;
  margin-bottom: 0;
}

.industry-banner-section > p {
  font-weight: 400;
  font-size: 16px;
  line-height: 181%;
  margin-bottom: 0;
}

.card-content h5 {
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  text-align: center;
}
.blogh2bgwhite{
  color: #fff;
}
#Blogsection_details-extend {
  height: 50vh;
 
  width: 100%;

  background: url("../public/Assets/blog/extended/How-Can-Extended-Reality.jpg")
    top center;
    background-repeat: no-repeat;
  background-size: 100% 100%;

}
#Blogsection_details-grow{
  height: 50vh;


  background: url("../public/Assets/blog/groth/Grow-Your-Business.jpg")
    top center;
    background-repeat: no-repeat;
  background-size: 100% 100%;

}

#Blogsection_details-top10{
  height: 50vh;
 
  width: 100%;

  background: url("../public/Assets/blog/top10/2023--SOFTWARE-DEVELOPMENT-TRENDS.jpg")
    top center;
    background-repeat: no-repeat;
  background-size: 100% 100%;

}
#Blogsection_details-unlock{
  height: 50vh;

  width: 100%;

  background: url("../public/Assets/blog/unlock/Unlock-the-Secrets-of-Remote.jpg")
    top center;
    background-repeat: no-repeat;
  background-size: 100% 100%;

}
#Benefits_of_Application{
  height: 50vh;

  width: 100%;

  background: url("../public/Assets/blog/unlock/thebenifits.jpg")
    top center;
    background-repeat: no-repeat;
  background-size: 100% 100%;

}
#Blogsection_details-socialmedia{
  height: 50vh;
 
  width: 100%;

  background: url("../public/Assets/blog/socialmedia/3-Social-Media-Marketing.jpg")
    top center;
    background-repeat: no-repeat;
  background-size: 100% 100%;

}

.card-content p {
  font-weight: 400;
  font-size: 14px;
  line-height: 156%;
  text-align: center;
}

@media screen and (max-width: 480px) {
  .industry-banner-section {
    padding: 50px 0px 10px 1px;
    padding-bottom: 60px;
  }

  .industry-banner-section > h1 {
    font-weight: 600;
    font-size: 24px;
    line-height: 73px;
  }

  .industry-banner-section > p {
    font-weight: 400;
    font-size: 16px;
    line-height: 189%;
  }

  .technology-div h5 {
    font-weight: 600;
    font-size: 14px;
    line-height: 28px;
  }
}

.card-content-section h4 {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #211e6d;
}

.card-content-section h5 {
  font-weight: 600;
  font-size: 16px;
  line-height: 27px;
  color: #101828;
}

.card-content-section p {
  font-weight: 400;
  font-size: 12px;
  line-height: 21px;
  color: #667085;
}

.heading-section {
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
}

.segment-div {
  background: #000033;
  position: absolute;
  width: 100%;
  text-align: center;
  padding: 5px;
  padding-top: 11px;
  bottom: 0;
}

.healthcare-technology-section {
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
}

.healthcare-image-section {
  flex: 25%;
  max-width: 25%;
  padding: 0 4px;
}

.healthcare-image-section img {
  margin-top: 8px;
  vertical-align: middle;
  width: 100%;
}

.healthcare-row-section {
  display: flex;
  flex-wrap: wrap;
  padding: 0 4px;
}

.technology-div {
  /* background: linear-gradient(180deg, rgba(255, 255, 255, 0) 27.64%, #FFFFFF 87.29%); */
  background-color: #211e6d;
  position: absolute;
  width: 100%;
  /* height: 100%; */
  padding: 6px;
  bottom: 0;
}

.technology-div h5 {
  font-weight: 500;
  font-size: 14px;
  line-height: 28px;
  margin-top: 5px;
}

.headthheading {
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 130%;
}

.overviewheading {
  font-style: normal;
  /* text-align: justify; */
  font-weight: 400;
  font-size: 24px;
  line-height: 130%;
}

.healthpara {
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 30px;
}

.capabilities {
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
}

.capapara {
  font-style: normal;
  font-weight: 200;
  font-size: 18px;
  line-height: 23px;
}

.ccolhaeding {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  color: #211e6d;
}

.cparagrap {
  font-style: normal;
  font-weight: 350;
  font-size: 16px;
  line-height: 160%;
}

.cbg {
  position: relative;
  width: 100%;
  background-image: url("../public/Assets/cbg.webp");
  background-repeat: no-repeat;
  background-size: cover;
}

.happymain {
  position: absolute;
  top: -9rem;
  right: 6rem;
  z-index: -1;
}

@media screen and (max-width: 380px) {
  .technology-div h5 {
    font-weight: 600;
    font-size: 12px;
    line-height: 28px;
  }

  .slick-next {
    display: none !important;
  }

  .slick-prev {
    display: none !important;
  }
}

.blockbgimg {
  width: 100%;
  background-image: url("../public/Assets/ai/ai/aibg.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}

.apply-btn {
  background: #211e6d;
  color: #fff;
  font-weight: 900;
  font-size: 16px;
  line-height: 22px;
  border: 2px solid #211e6d;
  padding: 12px 36px;
  border-radius: 6px;
  cursor: pointer;
  transition: 0.3s;
  text-transform: uppercase;
}

.apply-btn:hover {
  background-color: #fff;
  color: #211e6d;
}

.accordion-body ul li:before {
  content: "✓";
  color: #211e6d;
  font-weight: bolder;
  font-size: large;
  margin-left: 10px;
  padding-right: 10px;
}

.content-ul-section ul li:before {
  content: url("../public/Assets/Content-Marketing-Tick.webp");
  color: #211e6d;
  font-weight: bolder;
  font-size: 24px;
  margin-left: 10px;
  padding-right: 10px;
}
#clients{
  background-color: #EDECFF;
}
.clientslider{
  background-color: #fff;
  box-shadow: 0 4px 19px 0 rgba(33, 30, 109, 0.14);
  border-radius: 19px;
}
.sevesliderpage{
  width: 300px;
  position: absolute;
  top: 20%;
  right: 2%;
  background-color: #fff;
  box-shadow: 0 4px 19px 0 rgba(33, 30, 109, 0.14);
  border-radius: 19px;
}
.sil{
  position: relative;
}
.fonsinsevicespag{
  position: absolute;
  width: 331px;
  top: 24%;
  right: -22%;
  background-color: rgba(33, 30, 109, 0.83);
  border-radius: 4px;
}
.fonsinsevicespag p{
  font-size: 14px;
  font-weight: 400;
  line-height: 18.76px;
  color: #fff;
}
.pofordiv{
  position: relative;
}
.sevesliderpage p{
  font-size: 14px;
  font-weight: 400;
  line-height: 17.5px;

}
.bloghome .carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  /* justify-content: start !important; */
  padding: 0;
  margin-right: 0 !important;
  margin-bottom: -1rem;
  margin-left: 0 !important;
  list-style: none;
}
.sevi .carousel-indicators {
  width: 80%;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 44px;
  z-index: 2;
  display: flex;
  justify-content: space-between !important;
  padding: 0;
  margin-right: 0 !important;
  margin-bottom: -1rem;
  margin-left: 15px !important;
  list-style: none;
}
.clientslider .carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  /* justify-content: start !important; */
  padding: 0;
  margin-right: 0 !important;
  margin-bottom: -4rem;
  margin-left: 0 !important;
  list-style: none;
}
.clientsliderabout .carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  /* justify-content: start !important; */
  padding: 0;
  margin-right: 0 !important;
  margin-bottom: -4rem;
  margin-left: 0 !important;
  list-style: none;
}
.sevesliderpage .carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: start !important;
  padding: 0;
  margin-right: 0 !important;
  margin-bottom: 0;
  margin-left: 15px !important;
  list-style: none;
}
.borderformhome{
  box-shadow: 0 12px 24px 0 rgba(33, 30, 109, 0.25);
  border-radius: 17px;
}
.bloghome .carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #211E6D;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
.sevi .carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #211E6D;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}

.clientslider .carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #211E6D;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
.clientsliderabout .carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #211E6D;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
.sevesliderpage .carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #211E6D;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
.bloghome .carousel-indicators .active{
  opacity: 1;
}
.sevi .carousel-indicators .active{
  opacity: 1;
}
.clientslider .carousel-indicators .active {
  opacity: 1;
}
.clientsliderabout .carousel-indicators .active {
  opacity: 1;
}
.sevesliderpage .carousel-indicators .active {
  opacity: 1;
}
.ceosection{
  width: 100%;
  height: 745px;
  
  position: relative;
 
   
  
}
.redisalsce{
  position: absolute;
  bottom: 0;
  left: -15rem;
  width: 428px;
  height: 428px;
  background-color: #2AD2FF;
  filter: blur(150px);
  border-radius: 50%;
  z-index: -1;
}
.ceoroteddive{
  position: absolute;
  top: 0;
  right: 0;
  width:72.5%;
  height: 745px;
  z-index: 1;
  clip-path: polygon(100% 0, 25% 0, 44% 35%, 0 100%, 100% 100%);

  background-color: #00193E;
  /* transform: rotate(-35deg) */
}

.blogimginfo{
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

}
.blogimginfo h5{
  color: #2B86AA;
  font-weight: 700;
}
.blogimginfo h1{
 
  color: #00193E;
  font-weight: 700;
}
.ceo{
  list-style: none;
  
  padding-left: 0;
}
.ceo li{
  display: block;
  margin-bottom: 10px;
}
.ceo li a img{
  width: 30px;
}
.poimgceo {
  position: absolute;
 bottom: -25px;
  right: -60px;
  width: 96.5%;
}
.poimgceo1 {
  position: absolute;
 bottom: -47px;
  right: -80px;
  width: 96.5%;
}
/* Custom Development CSS */
.explorsevepage{
  border-radius: 8px;
  box-shadow: 0 4px 20px 0 rgba(33, 30, 109, 0.22);
}
.explorsevepage h5{
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
}
.explorsevepage p{
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
}
.custom-heading {
  font-weight: 600;
  font-size: 46px;
  line-height: 69px;
}

.custom-paragraph-heading {
  font-weight: 500;
  font-size: 26px;
  line-height: 39px;
}

.custom-development-section {
  background: #f1f5f9;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.16);
  margin-top: -150px;
  left: 7%;
  position: absolute;

  /* border-radius: 24px; */
}

.custom-section2 h2 p {
  font-weight: 600;
  font-size: 22px;
  line-height: 144%;
  text-align: center;
}

.custom-development-section2 h2 {
  font-weight: 600;
  font-size: 24px;
  line-height: 144%;
}

.custom-development-section2 p {
  font-weight: 400;
  font-size: 18px;
  line-height: 144%;
}

.custom-development-section3 h2 {
  font-weight: 600;
  font-size: 42px;
  line-height: 63px;
  text-align: center;
}

.div-section-headings h2 {
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
}

.div-section-headings p {
  font-weight: 400;
  font-size: 14px;
  line-height: 170.5%;
  text-align: center;
}

.kronos-section-headings h2 {
  font-weight: 600;
  font-size: 22px;
  line-height: 189%;
}

.kronos-section-headings p {
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
}

.get-in-touch-section h2 {
  font-weight: 700;
  font-size: 30px;
  line-height: 40px;
  color: #211e6d;
}

.get-in-touch-button {
  background: #211e6d;
  font-weight: 500;
  font-size: 20px;
  line-height: 288%;
  color: #ffffff;
  padding-left: 20px;
  padding-right: 20px;
}

.get-in-touch-button:hover {
  color: #ffffff;
  background: #211e6d;
}

.our-process-section {
  font-weight: 700;
  font-size: 42px;
  line-height: 63px;
  text-align: center;
  color: #211e6d;
}

.process-description-center h4 {
  font-weight: 700;
  font-size: 28px;
  line-height: 42px;
  color: #ffffff;
}

.process-description-center p {
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #ffffff;
}

.custom-ul-section {
  font-weight: 400;
  font-size: 24px;
  line-height: 178%;
}

/* CMS CSS */
.cms-heading {
  font-weight: 600;
  font-size: 56px;
  line-height: 71px;
}

.cms-paragraph-heading {
  font-weight: 400;
  font-size: 16px;
  line-height: 181%;
}

.cms-development-section2 h2 {
  font-weight: 600;
  font-size: 42px;
  line-height: 130%;
}

.cms-development-section2 p {
  font-weight: 400;
  font-size: 18px;
  line-height: 144%;
}

.section3-heading {
  font-weight: 600;
  font-size: 60px;
  line-height: 144%;
  text-align: center;
}

.cms-website-section h2 {
  font-weight: 600;
  font-size: 42px;
  line-height: 63px;
  color: #000000;
  text-align: center;
}

.cms-website-section p {
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  color: #000000;
}

.get-in-touch-cms-section {
  font-weight: 700;
  font-size: 40px;
  line-height: 60px;
  color: #ffffff;
}

.get-in-touch-cms-button {
  background: #ffffff;
  font-weight: 500;
  font-size: 20px;
  line-height: 288%;
  color: #211e6d;
  padding-left: 20px;
  padding-right: 20px;
}

.get-in-touch-cms-button:hover {
  color: #211e6d;
  background: #ffffff;
}

.section4-heading {
  font-weight: 700;
  font-size: 40px;
  line-height: 60px;
  text-align: center;
  color: #000000;
}

.section5-heading {
  font-weight: 700;
  font-size: 40px;
  line-height: 60px;
  text-align: center;
  color: #211e6d;
}

.cms-specialize-heading {
  font-weight: 600;
  font-size: 40px;
  line-height: 60px;
  color: #ffffff;
}

.cms-ul-li-section ul li {
  font-weight: 600;
  font-size: 24px;
  line-height: 230%;
  color: #ffffff;
}

.section6-heading {
  font-weight: 600;
  font-size: 42px;
  line-height: 63px;
  color: #000000;
}

.frameworksection {
  background: #fffbeb;
  box-shadow: 0px 9px 18px rgba(0, 0, 0, 0.25);
  margin-left: -200px;
}

.fremimg {
  position: absolute;
}

.mainhadinghome {
  position: absolute;
  left: 50%;
  bottom: 4rem;
  transform: translateX(-50%);
  z-index: 1;
  width: 98%;
}

.mainhadinghome h1 {
  font-size: 2.8rem;
  line-height: 1.5;
  font-weight: 300;
}

.contact-background-section {
  width: 100%;
  background-image: url(../public/Assets/76002.webp);
  background-position: center;
  background-size: cover;
  object-fit: cover;
  background-repeat: no-repeat;
}

.contact-heading {
  font-weight: 600;
  font-size: 34px;
  line-height: 51px;
  text-align: center;
  color: #000000;
}

.contact-form-section {
  background: #ffffff;
  box-shadow: 0px 4px 17px rgba(110, 104, 255, 0.13);
  border-radius: 0px 0px 30px 30px;
}

.contact-heading-section {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #101828;
}

.contact-submit-button {
  background: #211e6d;
  border: 1px solid #211e6d;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  padding: 12px 20px;
}

.map-heading-section {
  margin-top: 15px;
  font-size: 13px;
  line-height: 19px;
  font-weight: 500;
  margin-bottom: 0px;
  text-align: left;
  width: 50%;
}
.h4homd h4{
  color: #211E6D;
  font-size: 24px;
  font-weight: 500;
  line-height: 30px;
}

.web-development-heading {
  font-weight: 400;
  font-size: 22px;
  text-align: justify;
  line-height: 189%;
  color: #161616;
}

.download-button:hover {
  color: #000;
}

.web-work-heading {
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #000000;
}

.web-specialize-heading {
  font-weight: 600;
  font-size: 36px;
  line-height: 50px;
  color: #000000;
}

.devops-specialize-heading {
  font-weight: 600;
  font-size: 32px;
  line-height: 50px;
  color: #000000;
}

.kronos-implementation-heading {
  font-weight: 600;
  font-size: 36px;
  line-height: 40px;
  color: #000000;
  text-align: center;
}

.gallery-heading-section {
  font-weight: 600;
  font-size: 45px;
  line-height: 69px;
  color: #ffffff;
}

.contact-banner-web-section {
  background-color: black;
  height: 200px;
}

.highlightOption {
  background: #211e6d !important;
  color: #fff !important;
}

.multiSelectContainer li:hover {
  background: #211e6d !important;
  color: #fff !important;
  cursor: pointer !important;
}

.chip {
  align-items: center;
  background: #211e6d;
  border-radius: 11px;
  color: #fff;
  display: inline-flex;
  font-size: 13px;
  line-height: 19px;
  margin-bottom: 5px;
  margin-right: 5px;
  padding: 4px 10px;
}

.nav-pills .button-location:hover,
.nav-pills .button-location:focus {
  color: white !important;
  background-image: url(../public/Assets/Location-India-Active.webp);
  background-position: center;
  background-size: cover;
  object-fit: cover;
  background-repeat: no-repeat;
}

.nav-pills .button-location2.active {
  color: #fff !important;
  background-image: url(../public/Assets/Location-USA-Active.webp);
  background-position: center;
  background-size: cover;
  object-fit: cover;
  background-repeat: no-repeat;
}

.nav-pills .button-location1:hover,
.nav-pills .button-location1:focus {
  color: white !important;
  background-image: url(../public/Assets/Location-Dubai-Active.webp);
  background-position: center;
  background-size: cover;
  object-fit: cover;
  background-repeat: no-repeat;
}

.nav-pills .button-location2:hover,
.nav-pills .button-location2:focus {
  color: white !important;
  background-image: url(../public/Assets/Location-USA-Active.webp);
  background-position: center;
  background-size: cover;
  object-fit: cover;
  background-repeat: no-repeat;
}

.nav-pills .button-location3:hover,
.nav-pills .button-location3:focus {
  color: white !important;
  background-image: url(../public/Assets/Location-Canada-Active.webp);
  background-position: center;
  background-size: cover;
  object-fit: cover;
  background-repeat: no-repeat;
}
.nav-pills .button-location4:hover,
.nav-pills .button-location4:focus {
  color: white !important;
  background-image: url(../public/Assets/ukcolor.png);
  background-position: center;
  background-size: cover;
  object-fit: cover;
  background-repeat: no-repeat;
}
.nav-pills .button-location5:hover,
.nav-pills .button-location5:focus {
  color: white !important;
  background-image: url(../public/Assets/singaporecolor.png);
  background-position: center;
  background-size: cover;
  object-fit: cover;
  background-repeat: no-repeat;
}

.location-heading {
  font-weight: 700;
  color: #25373f;
  font-size: 41px;
  text-align: center;
  margin-bottom: 30px;
}

.nav-pills .button-location {
  color: black;
  background-color: transparent;
  background-image: url(../public/Assets/Location-India.webp);
  background-position: center;
  background-size: cover;
  object-fit: cover;
  background-repeat: no-repeat;
}

.nav-pills .button-location1 {
  color: black;
  background-color: transparent;
  background-image: url(../public/Assets/Location-Dubai.webp);
  background-position: center;
  background-size: cover;
  object-fit: cover;
  background-repeat: no-repeat;
}

.nav-pills .button-location2 {
  color: black;
  background-color: transparent;
  background-image: url(../public/Assets/Location-UAE.webp);
  background-position: center;
  background-size: cover;
  object-fit: cover;
  background-repeat: no-repeat;
}
.goodfirms{
  margin-left: -60px;
}
.whatsapp-fix{
  width: 50px !important;
  position:fixed !important;
  top: 320px !important;
  left: 10px !important;
  z-index: 999 !important;
}

  .clutch-widget {
    /* filter: invert(60%) brightness(600%); */
}

.nav-pills .button-location3 {
  color: black;
  background-color: transparent;
  background-image: url(../public/Assets/Location-Canada.webp);
  background-position: center;
  background-size: cover;
  object-fit: cover;
  background-repeat: no-repeat;
}
.nav-pills .button-location4 {
  color: black;
  background-color: transparent;
  background-image: url(../public/Assets/ukblack.png);
  background-position: center;
  background-size: cover;
  object-fit: cover;
  background-repeat: no-repeat;
}
.nav-pills .button-location5 {
  color: black;
  background-color: transparent;
  background-image: url(../public/Assets/singaporeblack.png);
  background-position: center;
  background-size: cover;
  object-fit: cover;
  background-repeat: no-repeat;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: black;
  background-color: var(--bs-nav-pills-link-active-bg);
}

.web-develop-overview {
  width: 90%;
  height: 660px;
}

.web-development {
  width: 100%;
  height: 74vh;
}

.insights-development {
  width: 100%;
  height: 66vh;
}

.why-should-img {
  width: 90%;
}

.differentiators-img {
  width: 100%;
}

.email-inquiry {
  text-decoration: none;
  color: #000;
}

.mainhadingcustom {
  position: absolute;
  left: 38%;
  top: 27%;
  transform: translateX(-50%);
  z-index: 1;
  width: 55%;
}

.development-description {
  padding-top: 151px;
}

.mainvideoslider {
  /* background-image: url('../public/Assets/home/homebanner.png'); */
  background-color: #000;
  /* height: 92vh;
  width: 100%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: end; */
}
.mainvideoslider h1{
 font-size: 36px;
 font-weight: 600;
line-height: 45px;
color: #fff;

}
/* .mainvideoslider a{
  padding: 13px 40px 13px 40px;
  background-image: linear-gradient(#006DB5,#02DBEC);
  color: #fff;
  border: 0;
} */

.mainvideoslider video {
  width: 100%;
  object-fit: cover;
  height: 92vh;
}

.mainvideoslider .carousel-indicators [data-bs-target] {
  width: 78px;
}

.button-tooltip-section button {
  width: 100%;
  display: inline-block;
  padding: 0;
  background: red;
  border-radius: 5px;
  margin-top: 0;
  position: relative;
  box-shadow: 0 0 5px rgb(0 0 0 / 30%);
}

.button-tooltip-section button:after,
.top-info .single-item:before {
  content: "";
}

.nav-pills .nav-link.active::after,
.nav-pills .show > .nav-link {
  position: absolute;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 7px solid transparent;
  border-bottom: 26px solid #211e6d;
  top: -34px;
  right: 22px;
  transition: all 0s ease;
  -moz-transition: all 0s ease;
  -webkit-transition: all 0s ease;
}

.custom-development-service {
  width: 100%;
  height: 100vh;
}

.api-website-section h5 {
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  color: #000000;
}

.api-website-section p {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}

#canvas {
  width: 200px;
  height: 200px;
  margin: 100px;
  padding: 0px;
  position: static;
  /* fixed or static */
  top: 100px;
  left: 100px;
}

.digital-transformation-section span {
  font-weight: 600;
  font-size: 36px;
  line-height: 60px;
  color: #000000;
}

.mapimg {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.fourofour_wrap {
  min-height: 100vh;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.top-to-btm {
  position: relative;
}

.icon-position {
  position: fixed;
  bottom: 40px;
  left: 25px;
  z-index: 20;
}

.icon-style {
  background-color: #211e6d;
  border: 2px solid #fff;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  color: #fff;
  cursor: pointer;
  animation: movebtn 3s ease-in-out infinite;
  transition: all 0.5s ease-in-out;
}

.icon-style:hover {
  animation: none;
  background: #fff;
  color: #211e6d;
  border: 2px solid #211e6d;
}

@keyframes movebtn {
  0% {
    transform: translateY(0px);
  }

  25% {
    transform: translateY(20px);
  }

  50% {
    transform: translateY(0px);
  }

  75% {
    transform: translateY(-20px);
  }

  100% {
    transform: translateY(0px);
  }
}

.cc-button-no {
  background: #211e6d !important;
  border: 0px;
  border-radius: 4px !important;
  box-shadow: none;
  color: #fff !important;
  cursor: pointer;
  flex: 0 0 auto;
  padding: 10px 20px 10px 20px !important;
  font-size: 14px !important;
}

.cc-button-wrap {
  display: flex !important;
  align-items: center !important;
  flex-wrap: wrap !important;
  vertical-align: middle !important;
}

.cc-button {
  background: #211e6d !important;
  border: 0px;
  border-radius: 4px !important;
  box-shadow: none;
  color: #fff !important;
  cursor: pointer;
  flex: 0 0 auto;
  padding: 10px 20px 10px 20px !important;
  font-size: 14px !important;
}

.cc-container {
  background: linear-gradient(
    rgba(225, 231, 240, 1) 0%,
    rgba(249, 250, 251, 1) 100%
  ) !important;
  text-align: left !important;
  z-index: 1000 !important;
  color: #000 !important;
  font-size: 12px !important;
  align-items: center !important;
  display: flex !important;
  justify-content: space-between !important;
  padding: 0px !important;
  width: 100% !important;
  border-radius: 4px !important;
  padding: 5px !important;
  box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset,
    rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset,
    rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px,
    rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px,
    rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px !important;
}

.cc-content {
  margin-left: 15px !important;
}

.circle12 {
  position: absolute;
  /* width: 200px;
  height: 200px; */
  /* border: 40px solid blue; */
  /* border-radius: 50%; */
  /* top: -50px;
  right: -100px; */
  top: 0px;
  right: 0px;
}

.ecommerce-heading h2 {
  font-weight: 600;
  font-size: 34px;
  line-height: 51px;
}

.ecommerce-heading p {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}
.ecommerce-development-section h2 {
  color: #000;
}



.ecommerce-development-section p {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  /* text-align: center; */
}

.ecommerce-card-body p {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  text-align: left;
}

.rotateMobile {
  transform: rotate(360deg);
  animation: rotation 60s linear infinite;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: var(--bs-nav-tabs-link-active-color);
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--bs-nav-tabs-link-active-border-color);
  font-size: 16px !important;
  font-weight: 600 !important;
  color: #211e6d !important;
}

.nav-link:focus,
.nav-link:hover {
  color: #211e6d !important;
}

.nav-tabs {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}



.classname {
  animation-name: cssAnimation;
  animation-duration: 1s;
  animation-iteration-count: 1;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
}

@keyframes cssAnimation {
  from {
    -webkit-transform: rotate(0deg) scale(1) skew(0deg) translate(100px);
  }

  to {
    -webkit-transform: rotate(0deg) scale(10) skew(0deg) translate(100px);
    opacity: 0.2;
  }
}

.graphic-design-heading h2 {
  font-weight: 600;
  font-size: 34px;
  line-height: 51px;
}

.graphic-design-heading p {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.email-marketing-section h2 {
  font-weight: 600;
  font-size: 34px;
  line-height: 51px;
}

.email-marketing-section p {
  font-weight: 500;
  font-size: 14px;
  line-height: 178%;
}


.all_center-img {
  width: 100%;
  height: 500px;
  border-radius: 10px;
}

.solution-head {
  font-size: 20px;
}

.insight-main-head {
  color: #fff;
}

.indus-head {
  color: #000;
}

.gallery-why-choose {
  color: #fff;
}
.mo{
  display: none;
}





/* block-resp */


.contact-main-head {
  font-weight: 400;
  font-size: 20px;
  line-height: 180%;
}

#Blogsection {
  height: 50vh;
  display: flex;
  align-items: center;
  color: #fff;
  width: 100%;
  position: relative;
  background: url("../public/Assets/blog/blogbanner.png")
    top center;
  background-size: cover;
  position: relative;
}
#Blogsection_details {
  height: 50vh;
  /* display: flex;
  align-items: center;
  color: #fff; */
  width: 100%;

  background: url("../public/Assets/blog/blog_details/awsbnner.png")
    top center;
    background-repeat: no-repeat;
  background-size: 100% 100%;

}
#awsblog{
  height: 50vh;
  /* display: flex;
  align-items: center;
  color: #fff; */
  width: 100%;

  background: url("../public/Assets/blog/blog_details/awsbnner.png")
    top center;
    background-repeat: no-repeat;
  background-size: 100% 100%;

}


#mobilapp{
  height: 50vh;
  /* display: flex;
  align-items: center;
  color: #fff; */
  width: 100%;

  background: url("../public/Assets/blog/craffting/Crafting-a-Winning-mobil-app.jpg")
    top center;
    background-repeat: no-repeat;
  background-size: cover;

}
#seleceforce{
  height: 50vh;
  /* display: flex;
  align-items: center;
  color: #fff; */
  width: 100%;

  background: url("../public/Assets/blog/salesblog/Revolutionize-Business-Operations00.jpg")
    top center;
    background-repeat: no-repeat;
  background-size: cover;

}
#protips{
  height: 50vh;
  /* display: flex;
  align-items: center;
  color: #fff; */
  width: 100%;

  background: url("../public/Assets/blog/guidline/6-Pro-Tips-for-Selecting.jpg")
    top center;
    background-repeat: no-repeat;
  background-size: cover;

}
#inspiring{
  height: 50vh;
  /* display: flex;
  align-items: center;
  color: #fff; */
  width: 100%;

  background: url("../public/Assets/blog/digital_transformation/Inspiring-Digital-Transformation.jpg")
    top center;
    background-repeat: no-repeat;
  background-size: cover;

}
#remotworking{
  height: 50vh;
  /* display: flex;
  align-items: center;
  color: #fff; */
  width: 100%;

  background: url("../public/Assets/blog/unlock/Unlock-the-Secrets-of-Remote.jpg")
    top center;
    background-repeat: no-repeat;
  background-size: cover;

}
.ourservicespage h3{
  font-weight: 200;
    font-size: 25px;

}
.ourservicespage h6{
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
 
}
.ourservicespage p{
  font-weight: 300;
  font-size: 15px;
}
.blog_heading {
  color: #fff;
  font-size: 2rem;
  font-size: sans-serif;
}
.blog_heading1 {
  color: #000;
  font-size: 1.6rem;
}
.blog_para {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
 
  color: #000;
}
.Read_art {
  color: #000;
  font-size: 15px;
  text-decoration: none;
  font-weight: 600;
}
.Read_noww{
  color:#000;
  border: solid 1px #3bcff0;
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
  background-color: #3bcff0;
  padding: 13px;
  border-radius: 25px;
  
}
.card-title{
 color: #000;
}
.card-text{
  color: #000;
 }
 .fontparagraf p{
  font-size: 16px;
  font-weight: 400;
  color: #3D3D3D;
 }
 .blogimg img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 9px 0 0 9px;
 }
 
 .fontparagraf a{
  text-decoration: none;
  font-size: 18px;
  font-weight: 400;
  color: #000;
  display: block;
  
 }
 .fontparagraf a span{
  /* display: inline-block; */
  position: relative;
  width: 50px;
  height: 50px;
  border: 1px solid red;
  border-radius: 50px;
  transition: all .3s linear;
 }
 .fontparagraf a:hover span{
  width: 100px;
  height: 50px;
  border: 1px solid red;
  border-radius: 50px;
 }
 .fontparagraf a:hover i{
  padding-left: 5px;
  transition: all .3s linear;
 }
 .fontparagraf h4{
  font-weight: 600;
 }
 .righblogimg img{
  border-radius: 15px;
 }
.ceoname{
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  z-index: 1;
}
 .ceoname h1{
  color:transparent;
  text-align: end;
  /* font-size: 55px; */
  letter-spacing: -1px;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color:rgba(255, 255, 255, 0.27);
 }
 .praceo{
  margin-left: auto;
 
  width: 50%;
  color: #fff;
  text-align: right;
 }
 .praceo2{
  margin-left: auto;
 
  width: 50%;
  color: #fff;
  text-align: right;
 }
 .praceo p{
  font-size: 24px;
  line-height: 34px
 }
 .aboutceo{
  border-top: 3px solid #fff;
  border-right: 3px solid #fff;
  color: #fff;
  position: relative;
 }
 .aboutceo::after{
  position: absolute;
  content: "";
  width: 50%;
  height: 3px;
  right: 0;
  bottom: 0;
  background-color: #fff;
 }
 .weblink h5 a{
  text-decoration: none;
  color: #FFF;
 }
 .allweb{
  margin-bottom: 0;
 
  list-style: none;
 }
 .allweb li{
  display: inline;
  margin: 10px;
  
 }
 .allweb li a img{
  width: 35px;
 }
 .aboutceo p{
  margin-bottom: 0;
  font-size: 14px;
 }
 
 .cardpart a{
  text-decoration: none;
  color: #000;
 }
 .cardpart p{
  font-size: 14px;
  font-weight: 600;
 }
 .content a{
  text-decoration: none;

 }

 .bg_img{
  background-image: url("../public/Assets/blog/social-media.jpg");
  background-repeat: no-repeat;
  object-fit: cover;
  background-color: rgba(0, 0, 0, 0.5);
  background-blend-mode: overlay;
  transform: scale(1);
  transition: .3s linear;
  border-radius: 15px;
 }
 .bg_img:hover{
  transform: scale(1.1);
 }
 
 .bg_img a{
  text-decoration: none;
  color: #fff;
 }

 .icon-bar {
  position: sticky;
  top:30%
  
}

/* Style the icon bar links */
.icon-bar a {
  display: block;
  text-align: center;
 width: 50px;
 height: 50px;
  transition: all 0.3s ease;
  color: white;
  font-size: 14px;
  margin-bottom: 10px;
  border-radius: 50%;
}

/* Style the social media icons with color, if you want */
.icon-bar a:hover {
  background-color: #000;
}

.facebook {
  background: #3B5998;
  color: white;
}

.twitter {
  background: #55ACEE;
  color: white;
}

.google {
  background: #dd4b39;
  color: white;
}

.linkedin {
  background: #007bb5;
  color: white;
}

.youtube {
  background: #bb0000;
  color: white;
}
.bgcolorblog{
  position: sticky;
  top: 10%;
  background-color: #F1F9FF;
}
.homecontactimg img{
  /* max-height:700px; */
  width: 100%;
  aspect-ratio: 1;
  object-fit: cover;

}
.foncolrfooter{
  color: #000;
}
.blogtabs{
  font-size: 16px;
  margin: 0;
}
.blogtabs li{
  display:inline-block;
  padding-right: 42px;
}
.blogtabs a{
  text-decoration: none;
  color: #000;
}
.blogtabbg{
  background-color: #ECECEC;
}
.blogimgb{
  overflow: hidden;
  border-radius: 8px;
  border: 1px solid #D9D9D9;
}
.homblogclider{
  width: 100%;
  height: 265px;
  overflow: hidden;
}
.blogimgb img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px 8px 0 0;
 
  transition:all 0.5s ease-in-out;
}
.blogimgb img:hover{
  transform: scale(1.1);
}
.blogimgb h5{
  font-size: 20px;
  font-weight: 600;
  color: #000;
}
.srv {
  background-color: #fff;
}
.srv h5{
  font-size: 18px;
  font-weight: 600;
  color: #000;
}
.srv p{
  font-size: 14px;
  font-weight: 400;
  color: #000;
  line-height: 18px;
}
.blogfooterbanner{
 background-color: #211E6D;
 color: #fff;
 border-radius: 24px;
}
.blogfooterbanner a{
  
  background-color: #fff;
  color: #000;
  text-decoration: none;
  border-radius: 8px;
 
  padding: 12px 44px;

 
  text-decoration: none;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  transition: all .15s ease-in-out;
  cursor: pointer;
  border-radius: 10px;
  display: inline-block;
  overflow: hidden;
}
.blogfooterbanner a:hover{
  font-weight: 400;
}
.page-item:first-child .page-link,.page-item:last-child .page-link{
  border-radius: 8px;
  
  color: #344054;
  font-weight: 500;
  font-size: 14px;
}
.pagination li{
  margin-right: 10px;
}
.previous{
  display: inline-block;
  padding: 8px 14px 8px 14px;
  text-decoration: none;
  border-radius: 8px;
  border: 1px solid #D0D5DD;
  color: #344054;
  font-weight: 500;
  font-size: 14px;
}
.previous:hover{
  color: #000;
}
.page-link{
  border: none;
  border-radius: 8px;
   font-weight: 500;
  font-size: 14px;
  color: #667085;
}
.active>.page-link, .page-link.active{
  background-color: #F9F5FF!important;
  color: #211E6D!important;
}
.page-link:focus{
  box-shadow: none;
}
/* .input-group-text{
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #DDDDDD;
  border-radius: 0;
} */
/* .input-group>:not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback){
  border: 0;
  background-color: transparent;
  border-bottom: 1px solid #DDDDDD;
  border-radius: 0;
} */
.corm{
  border-radius: 5px 0 0 5px;
  border-color: #ced4da;
}
.blogpagetabs::placeholder{
  font-weight: 400;
  font-size: 16px;
  color: #000;
}

.blogdetailspage h3{
  color: #211E6D;
  font-weight: 600;
  font-size: 32px;
}
.blogdetailspage h2{
  font-weight: 600;
  font-size: 28px;
}
.blogdetailspage p{
 color: #3D3D3D;
 font-size: 16px;
 font-weight: 400;
 line-height: 32px;
}
.blogdetailspage p strong{
  color: #211E6D;
  font-size: 16px;
  font-weight: 600;
  line-height: 32px;
 }
.sidebarblogdetails{
  background-color: #F5F4FF;
  border: 1px solid #D9D9D9;
  border-radius: 15px;
}
.sidebarblogdetails p{
  font-size: 16px;
  font-weight: 400;
}

.sidebarblogdetails ol li a{
  display: block;
  padding-bottom: 10px;
  text-decoration: none;
  color: #3D3D3D;
  font-size: 16px;
  font-weight: 400;
 }
 .sidebarblogdetails ol li::marker{
  color: #211E6D;

 }
 .sidebarblogdetails ol li a:hover{
  color: #211E6D;
 }
 .sidebarblogdetails{
  position: sticky;
  top: 10%;
 }
 .relatedblogs{
  color: #211E6D;
 }
 
 .whydfxabouthome{
  margin-top: 100px;
 }
 .industriesverce{
  width: 100%;
  background-image: url('../public/Assets/home/industrieshomeimg.png');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: 100% 100%;
  object-fit: cover;
 }
 .Aerospace{
  /* width: 251px; */
  height: 90px;
  display: block;
  padding: 8px 12px 8px 12px;
  background-color: rgba(255, 255, 255, 0.12);
  color: #fff;
  text-decoration: none;
  word-wrap:break-word;
font-size: 20px;
border-radius: 12px;

 cursor: pointer;
    transition: all 0.3s ease;
    /* position: relative;
   
    box-shadow: inset 2px 2px 2px 0px rgba(255,255,255,.5), 7px 7px 20px 0px rgba(0,0,0,.1), 4px 4px 5px 0px rgba(0,0,0,.1);
    outline: none; */
 }
 /* .btn-2 {

  background: linear-gradient(0deg, rgba(96,9,240,1) 0%, rgba(129,5,240,1) 100%);
  border: none;
} */
/* .btn-2:hover {
  box-shadow:  inset -4px -4px 6px 0 rgba(255,255,255,.2), inset 4px 4px 6px 0 rgba(0, 0, 0, .4);
} */
 .Aerospace:hover{
  color: #fff;
  box-shadow: -1px 3px 17px 0 rgba(124, 119, 255, 0.28);
  border-color: #fff;
  border: 1px solid #fff;
  
 }
 
 .tab-pane.fade {
  transition: all .1s ease-in-out;
  
transform: scale(0.1);
opacity: 0.5;

}
 
.tab-pane.fade.show {  
transform: scale(1);
opacity: 1;
}
.processhover:hover .poligon{
  background: #211E6D;
  
}
.processhover:hover .poligon h1{
  color: #211E6D;
  
}

.poligon {
  display: inline-block;
  position: relative;
  width: 9.375rem;
  height: 9.375rem;
  background: #000;
  box-sizing: border-box;
  -webkit-clip-path: polygon(92.32051% 40%, 93.79385% 43.1596%, 94.69616% 46.52704%, 95% 50%, 94.69616% 53.47296%, 93.79385% 56.8404%, 92.32051% 60%, 79.82051% 81.65064%, 77.82089% 84.50639%, 75.35575% 86.97152%, 72.5% 88.97114%, 69.3404% 90.44449%, 65.97296% 91.34679%, 62.5% 91.65064%, 37.5% 91.65064%, 34.02704% 91.34679%, 30.6596% 90.44449%, 27.5% 88.97114%, 24.64425% 86.97152%, 22.17911% 84.50639%, 20.17949% 81.65064%, 7.67949% 60%, 6.20615% 56.8404%, 5.30384% 53.47296%, 5% 50%, 5.30384% 46.52704%, 6.20615% 43.1596%, 7.67949% 40%, 20.17949% 18.34936%, 22.17911% 15.49361%, 24.64425% 13.02848%, 27.5% 11.02886%, 30.6596% 9.55551%, 34.02704% 8.65321%, 37.5% 8.34936%, 62.5% 8.34936%, 65.97296% 8.65321%, 69.3404% 9.55551%, 72.5% 11.02886%, 75.35575% 13.02848%, 77.82089% 15.49361%, 79.82051% 18.34936%);
  clip-path: polygon(92.32051% 40%, 93.79385% 43.1596%, 94.69616% 46.52704%, 95% 50%, 94.69616% 53.47296%, 93.79385% 56.8404%, 92.32051% 60%, 79.82051% 81.65064%, 77.82089% 84.50639%, 75.35575% 86.97152%, 72.5% 88.97114%, 69.3404% 90.44449%, 65.97296% 91.34679%, 62.5% 91.65064%, 37.5% 91.65064%, 34.02704% 91.34679%, 30.6596% 90.44449%, 27.5% 88.97114%, 24.64425% 86.97152%, 22.17911% 84.50639%, 20.17949% 81.65064%, 7.67949% 60%, 6.20615% 56.8404%, 5.30384% 53.47296%, 5% 50%, 5.30384% 46.52704%, 6.20615% 43.1596%, 7.67949% 40%, 20.17949% 18.34936%, 22.17911% 15.49361%, 24.64425% 13.02848%, 27.5% 11.02886%, 30.6596% 9.55551%, 34.02704% 8.65321%, 37.5% 8.34936%, 62.5% 8.34936%, 65.97296% 8.65321%, 69.3404% 9.55551%, 72.5% 11.02886%, 75.35575% 13.02848%, 77.82089% 15.49361%, 79.82051% 18.34936%);
}
.poligon h1 {
  position: absolute;
  top: 2px; /* equal to border thickness */
  left: 2px; /* equal to border thickness */
  width:9.125rem; /* container height - (border thickness * 2) */
  height: 9.125rem; /* container height - (border thickness * 2) */
  background-color: #EDECFF;
  color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-clip-path: polygon(92.32051% 0%, 93.79385% 43.1596%, 94.69616% 46.52704%, 95% 50%, 94.69616% 53.47296%, 93.79385% 56.8404%, 92.32051% 60%, 79.82051% 81.65064%, 77.82089% 84.50639%, 75.35575% 86.97152%, 72.5% 88.97114%, 69.3404% 90.44449%, 65.97296% 91.34679%, 62.5% 91.65064%, 37.5% 91.65064%, 34.02704% 91.34679%, 30.6596% 90.44449%, 27.5% 88.97114%, 24.64425% 86.97152%, 22.17911% 84.50639%, 20.17949% 81.65064%, 7.67949% 60%, 6.20615% 56.8404%, 5.30384% 53.47296%, 5% 50%, 5.30384% 46.52704%, 6.20615% 43.1596%, 7.67949% 40%, 20.17949% 18.34936%, 22.17911% 15.49361%, 24.64425% 13.02848%, 27.5% 11.02886%, 30.6596% 9.55551%, 34.02704% 8.65321%, 37.5% 8.34936%, 62.5% 8.34936%, 65.97296% 8.65321%, 69.3404% 9.55551%, 72.5% 11.02886%, 75.35575% 13.02848%, 77.82089% 15.49361%, 79.82051% 18.34936%);
  clip-path: polygon(92.32051% 40%, 93.79385% 43.1596%, 94.69616% 46.52704%, 95% 50%, 94.69616% 53.47296%, 93.79385% 56.8404%, 92.32051% 60%, 79.82051% 81.65064%, 77.82089% 84.50639%, 75.35575% 86.97152%, 72.5% 88.97114%, 69.3404% 90.44449%, 65.97296% 91.34679%, 62.5% 91.65064%, 37.5% 91.65064%, 34.02704% 91.34679%, 30.6596% 90.44449%, 27.5% 88.97114%, 24.64425% 86.97152%, 22.17911% 84.50639%, 20.17949% 81.65064%, 7.67949% 60%, 6.20615% 56.8404%, 5.30384% 53.47296%, 5% 50%, 5.30384% 46.52704%, 6.20615% 43.1596%, 7.67949% 40%, 20.17949% 18.34936%, 22.17911% 15.49361%, 24.64425% 13.02848%, 27.5% 11.02886%, 30.6596% 9.55551%, 34.02704% 8.65321%, 37.5% 8.34936%, 62.5% 8.34936%, 65.97296% 8.65321%, 69.3404% 9.55551%, 72.5% 11.02886%, 75.35575% 13.02848%, 77.82089% 15.49361%, 79.82051% 18.34936%);
}
.line{
  width: 98px;
  height: 107px;
  border-left: 4px dashed #3D3D3D;
  border-bottom: 4px dashed #3D3D3D;
  position: relative;
}
.line img{
  position: absolute;
  bottom:-13px;
  right: -5px;
  width: 20px;
}
.ceo1{
  display: none;
}
.ceolinkwebside{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.line4{
  margin-top: 50px;
  width: 98px;
  height: 50px;
  /* border-left: 4px dashed #3D3D3D; */
  /* border-radius: 10px 20px 0 0; */
  position: relative;
  border:2px dashed #3D3D3D;
  border-color:#000 transparent transparent transparent;
  border-radius: 60%/10px 50px 0 0;
}
.line4 img{
 width: 10px;
  position: absolute;
   right: 2px;
  top: 12px;
  transform: rotate(45deg);
}

.line5{
  margin-top: 50px;
  width: 98px;
  height: 20px;
  /* border-left: 4px dashed #3D3D3D; */
  /* border-radius: 10px 20px 0 0; */
  position: relative;
  border:2px dashed #3D3D3D;
  border-color: transparent transparent #000 transparent;
  border-radius: 10px 0px 60%/20px 2px ;
}
.line5 img{
 width: 10px;
  position: absolute;
   right: -3px;
  top: -1px;
  transform: rotate(203deg);
}
.seporces{
  margin-top: 10px;
  color: #3D3D3D;
  font-weight: 600;
  font-size: 15px;
}
.line2{
  width: 98px;
  height: 107px;
  border-left: 4px dashed #3D3D3D;
  border-top: 4px dashed #3D3D3D;
  position: relative;
}
.line20{
  width: 98px;
  height: 107px;
  border-left: 4px dashed #3D3D3D;
  /* border-top: 4px dashed #3D3D3D; */
  position: relative;
}
.line20 img{
  position: absolute;
  top:0;
  left: -10px;
  width: 20px;
  transform: rotate(28deg);

}
.line2 img{
  position: absolute;
  top:-13px;
  right: -5px;
  width: 20px;
}

.processhover:hover .line, .processhover:hover .line2{
  border-color: #211E6D;
}
.processhover:hover .line, .processhover:hover .line20{
  border-color: #211E6D;
}

.processhover:hover .propera p{
  color: #211E6D;
  border-color: red;
}
.propera p{
  font-size: 15px;
  font-weight: 500;
  line-height: 19px;
  color: #000;
}
.processhover:hover .primg img{
  filter: invert(19%) sepia(11%) saturate(6912%) hue-rotate(219deg) brightness(90%) contrast(113%);
}
.processhover:hover .poligon1 img{
  filter: invert(19%) sepia(11%) saturate(6912%) hue-rotate(219deg) brightness(90%) contrast(113%);
}
.processhover:hover p{
  color: #211E6D;
}


.hombuttonbanner{
  display: inline-block;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 192px;
  padding: 13px 40px;
  text-decoration: none;
  color: #fff;
  
  

}
.hombuttonbanner:hover{
  color: #fff;
}
.hombuttonbanner p{
  position: relative;
  font-size: 18px;
  font-weight: 500;
  /* z-index: 1050; */
}
.hombuttonbanner .divbottn{
  position: absolute;
  left: -10px;
  top: 5px;
  width: 40px;
  height: 40px;
  border-radius: 30px;
  border: 1px solid #fff;
  transition: all .3s linear;
  text-align: left;
  z-index: 0;
  padding: 8px 20px 20px 12px;


}
.hombuttonbanner:hover .divbottn{
  width: 90%;
  background-color: #2B86AA;
  color: #fff;
  border-color:#2B86AA;
}

/* .preloader {
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  width: 100%;
  background: #fff;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 50;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  z-index: 1050;
  overflow: hidden !important;
} */
/* .texts-container {
  display: flex;
  justify-content: center;
  align-items: center;
  
  height: 100vh;
  width:100%;
  font-size: 20px;
  overflow: hidden;
  font-weight: 800;
  opacity: 0;
  color: white;
} */
.informationceo{
  color: #211E6D;
  font-size: 70px;
  font-weight: 600;
}
.informationceo span{
  font-weight: 300;
}
.informationceo2{
  color: #211E6D;
  font-size: 57px;
  font-weight: 600;
}
.informationceo2 span{
  font-weight: 300;
}
.counterserveces{
  /* width: 184px; */
  box-shadow: 0 11px 24px 0 rgba(33, 30, 109, 0.22);
  border-radius: 24px;
  background-color: #fff;
}
.councolor1{
  color: #3E36FF;
}
.councolor2{
  color: #0094E8;
}
.councolor3{
  color: #FF5151;
}
.councolor4{
  color: #EDAB00;
}
.animationdiv{
  /* width: 100vw; */
  position: relative;
  /* height: 90vh; */
	overflow: hidden;
	
}
#team4{
  display: none;
  background-color: #EDECFF;
}
.respondive{
  background-color: #EDECFF;
}

.respondivemobile{
  background-image: url('../public/Assets//services_img/sebagimg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-color:#211E6D;
  background-blend-mode: overlay;
}
.animationdiv::before{
  content: "";
  position: absolute;
  top: -100px;
  width: 871px;
  height: 967px;
  background-color: #5CC4FF;
  filter: blur(500px);
  border-radius: 50%;
  z-index: -1;
  animation: gradient1 15s linear infinite;
}

@keyframes gradient1 {
  0%   {left:-100px; top:-100px;}
  25%  { left:63%; top:0px;}
  50%  { left:63%; top:50%;}
  75%  { left:63%; top:0%;}
  100% { left:0px;}
}
.animationdiv::after{
  content: "";
  right: 0;
  bottom: 0;
  position: absolute;
  width: 783px;
  height: 752px;
  background-color: #322AFD;
  filter: blur(150px);
  border-radius: 50%;
  z-index: -1;
  animation: gradient2 15s linear infinite;
}

@keyframes gradient2 {
  0%   {right:0px; bottom:0px;}
  25%  { right:63%; bottom:0px;}
  50%  { right:63%; bottom:50%;}
  75%  { right:63%; bottom:0px;}
  100% { right:0px;}
}
.mocenter{
  text-align: end;
}
.poligon1{
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px 30px 30px 0;
  width: 100px;
  height: 100px;
  border:1px solid #fff;
  box-shadow: inset 4px 4px 30px 0 #D1D9E6, inset -4px 4px 30px 0 rgba(255, 255, 255, 0.5);
}
.respondivemobile{
  display: none;
}
.slidermobleresponsive{
  display: none;
}
.seseliverleftborder{
  position: relative;
}
.seseliverleftborder::after{
position: absolute;
content: "";
top: 10%;
right: 0;
width: 5px;
height: 80%;
background-color: #211E6D;
  
}
.faqdevelopment{
  padding-left: 19%;

}
.resdevm1{
  display: none;
}
.mobprohome{
  display: none;
}

/* digital marketing page start */
.uiuxsection1{
  width: 100%;
  height: 70vh;
  background-image: url(../public/Assets/digitalmarketing/digitalmarketing-banner.png);
  background-position: center;
  background-size: cover;
  object-fit: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  color: #fff;
}
.digitalmarketingsection{
  width: 100%;
  height: 70vh;
  background-image: url(../public/Assets/digitalmarketing/banner.png);
  background-position: center;
  background-size: cover;
  object-fit: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  color: #fff;
}
.uiuxsection2{
  width: 100%;
  background-image: url(../public/Assets/digitalmarketing/uiux_img_banner.png);
  background-position: center;
  background-size: cover;
  object-fit: cover;
  background-repeat: no-repeat;
}
.develpmentser1{
  border-radius: 22px;
  /* background-color: #EDECFF; */
  position: relative;
}
.develpmentser1 a{
  text-decoration: none;
  padding: 13px 40px;
  background-color: #211E6D;
  color: #fff;
  border-radius: 8px;
  font-size: 18px;
  line-height: 23px;
  font-weight: 500;
}
.develpmentser1 a:hover{
  background-color: #211E6D;
  color: #fff;
}
.develpmentser1 a:active{
  background-color: #211E6D!important;
  color: #fff!important;
  border-color: #211E6D!important;
}
.dmarinsec{
  background-color: #EDECFF;
}
.dstratgey{
  background-color: #00BA00;
  border-radius: 31px;
}
.dstratgey2{
  background-color: #F44336;
  border-radius: 31px;
}
.strageryinner{
  background-color: #fff;
  border-radius: 31px;
  position: relative;

}
.strageryinner2{
  background-color: #fff;
  border-radius: 31px;
  position: relative;


}
.strageryinner p{
  font-weight: 600;
}
.strageryinner2 p{
  font-weight: 600;
}
.strageryinner2 ul{
  list-style: inside url("../public/Assets/digitalmarketing/straclose.png");
  padding-left: 0;

}
.strageryinner ul{
  list-style: inside url("../public/Assets/digitalmarketing/stratgey.png");
  padding-left: 0;

}
.strageryinner2 ul li{
  font-size: 14px;
  /* display: block; */
  padding-bottom: 10px;
}
.strageryinner ul li{
  font-size: 14px;
  /* display: block; */
  padding-bottom: 10px;
}
.righditearo{
  position: absolute;
  width: 77px;
  height: 77px;
  border-radius: 50px;
box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  display: grid;
  place-items: center;
  font-size: 50px;
  color: #00BA00;
  right: 10%;
  top: 20%;
}
.righditearo2{
  position: absolute;
  width: 77px;
  height: 77px;
  border-radius: 50px;
box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  display: grid;
  place-items: center;
  font-size: 50px;
  color: #F44336;
  right:10%;
  top: 20%;
}

.crepra{
  font-size: 14px!important;
}
.carjobs{
  width: 64%;
  margin: auto;
  background-color: #EDECFF;
  border: 2px solid #211E6D;
  border-radius: 18px;
}
.jobsmodal .modal .modal-dialog{
  max-width:560px!important;
}
.jopformdetail label{
  font-size: 15px;
  font-weight: 600;
}
.jopformdetail .form-control{
  border-color:#1F81B9;
  padding: 10px;
  border-radius: 8px;
}

::file-selector-button {
 
  border-radius: 8px;
  border: 1px solid #211E6D;
  color: #211E6D;
  cursor: pointer;
  display: inline-block;
	
	font-size: 13px;
  font-weight: 600;
  margin-bottom: 1rem;
  outline: none;
  padding: .5rem 14px;
 
  

}
.jopformdetail{
  width: 80%;
}
.crage{
  font-size: 14px;
  font-weight: 400;
  color: #000;
}
.crage1{
  font-size: 18px;
  font-weight: 600;
  color: #000;
}
.cragemofsl{
  font-size: 14px;
  font-weight: 600;
  color: #211E6D;
}
.jopdescriptaion{
  font-size: 18px;
  font-weight: 600;
  color: #211E6D;
}
.dscription li{
  font-size: 14px;
  font-weight: 400;
  color: #211E6D;
  padding-bottom: 10px;
}
.carijptbtn{
  border: 0;
  font-size: 14px;
  background-color: transparent;
}
.carijptbtn svg{
  transform: rotate(-45deg);
  color: #211E6D;
}
.blogtabs1{
  font-size: 16px;
  margin: 0;
}
.seimdiv{
  width: 150px;
}
.blogtabs1 li{
  display:inline-block;
  padding: 10px;
}
.blogtabs1 a{
  text-decoration: none;
  color: #211E6D;
  padding: 8px 14px;
  border: 1px solid #211E6D;
  border-radius: 30px;
  background-color: #EDECFF;
  transition: all .3s linear;
}
.blogtabs1 .active{
  background-color: #211E6D;
  color: #fff;
}
.blogtabs1 a:hover,.blogtabs1 a:active, .blogtabs1 a:focus{
 background-color: #211E6D;
 color: #fff;
}


/* ui-ux page css start here */



.uiuxsection1{
  width: 100%;
  height: 70vh;
  background-image: url(../public/Assets/uiux/uiux_banner.png);
  background-position: center;
  background-size: cover;
  object-fit: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  color: #fff;
}

.uiuxsection2{
  width: 100%;
  background-image: url(../public/Assets/uiux/uiux_img_banner.png);
  background-position: center;
  background-size: cover;
  object-fit: cover;
  background-repeat: no-repeat;
}

.develpmentser1{
  border-radius: 22px;
  /* background-color: #EDECFF; */
  position: relative;
}

.develpmentser1 a{
  text-decoration: none;
  padding: 13px 40px;
  background-color: #211E6D;
  color: #fff;
  border-radius: 8px;
  font-size: 18px;
  line-height: 23px;
  font-weight: 500;
}

.develpmentser1 a:hover{
  background-color: #211E6D;
  color: #fff;
}
.develpmentser1 a:active{
  background-color: #211E6D!important;
  color: #fff!important;
  border-color: #211E6D!important;
}


/* ui-ux page css end here */

/* industry page css start here */
.industry-banner {
  background-image: url("../public/Assets/industries/industries_banners.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 70vh;
}
.develpmentser1{
  border-radius: 22px;
  position: relative;
}
.develpmentser1 a{
  text-decoration: none;
  padding: 13px 40px;
  background-color: #211E6D;
  color: #fff;
  border-radius: 8px;
  font-size: 18px;
  line-height: 23px;
  font-weight: 500;
}
.develpmentser1 a:hover{
  background-color: #211E6D;
  color: #fff;
}
.develpmentser1 a:active{
  background-color: #211E6D!important;
  color: #fff!important;
  border-color: #211E6D!important;
}
.robot_main{
  position: relative;
}
.aero{
  max-height: 700px;
  overflow-y: auto;
}
.fontparagraf1{
  background-color: #fff;
  border-radius: 4px;
  border: none;
}
.gradually-head{
  font-weight: 600;
font-size: 24px;
line-height: 30px;
}
.gradually-para{
  font-weight: 400;
font-size: 14px;
line-height: 134%;
}
.robot-img{
  position: absolute;
  left: -118px;
  bottom: 0px;
}

/* industry page css end here */
/* aerospace page css end here */
.Aero-sapce-banner{
  background-image: url("../public/Assets/industries/aerospace/aerospacebanner.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 70vh;
  position: relative;
  
}
.aruomotive{
  background-image: url("../public/Assets/industries/auromotive/automotivbebanner.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 70vh;
  position: relative;
  
}
.education{
  background-image: url("../public/Assets/industries/education/Edu_banner.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 70vh;
  position: relative;
}
.healthcare{
  background-image: url("../public/Assets/industries/healthcare/healthcare.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 70vh;
  position: relative;
  
}
.media-banner{
  background-image: url("../public/Assets/industries/media&enter/media_banner.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 70vh;
  position: relative;
}
.retail-banner{
  background-image: url("../public/Assets/industries/retail/ratail_banner.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 70vh;
  position: relative;
}
.travel-banner{
  background-image: url("../public/Assets/industries/travel/travel_banner.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 70vh;
  position: relative;
}
.utiliti-banner{
  background-image: url("../public/Assets/industries/utiliti/utiliti_banner.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 70vh;
  position: relative;
}
.hightech{
  background-image: url("../public/Assets/industries/hightech/hightechbanner.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 70vh;
  position: relative;
  
}
.banking{
  background-image: url("../public/Assets/industries/banking/bankingbanner.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 70vh;
  position: relative;
  
}
.insurance-banner{
  background-image: url("../public/Assets/industries/insurance/Insurance_banner.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 70vh;
  position: relative;
}
.hospatility{
  background-image: url("../public/Assets/industries/hospatility/Hospatility_banner.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 70vh;
  position: relative;
}
.bgcoloindbanner{
 width: 60%;

  position: absolute;
  right: 0;
  background-color: #211E6D;
  padding-top: 10%;
}
.bgcoloindbanner p{
  font-size: 32px;
  font-weight: 300;
  color: #fff;
  line-height: normal;
}
.aautomo{
 width: 60%;

  position: absolute;
  right: 0;
  bottom: 0;
  background-color: #211E6D;
  padding-top: 4%;
}
.aautomo p, .industry-banner p{
  font-size: 32px;
  font-weight: 300;
  color: #fff;
  line-height: normal;
}
.aeropsul{
 padding-left: 0;
 list-style-position:inside
}
.arulpadding{
  padding-left: 14%;
}

/* aerospace page css end here */

/* digital marketing page end */
 /* media 1200 start ere========================================================================================================= */
 @media screen and (min-width: 2560px) {
  
  .praceo{
    padding: 1rem 0;
  }
  .praceo2{
    padding: 3.9rem 0!important;
  }
 }
 /* media 1200 start ere========================================================================================================= */
 @media screen and (max-width: 2560px) {
  .praceo{
    padding: 1rem 0;
  }
  .praceo2{
    padding: 3.9rem 0!important;
  }
 }
 /* media 1200 start ere========================================================================================================= */
 @media screen and (max-width: 1400px) {
  .ceosection .container {
    width: 100%!important;
  }
  .praceo{
    padding: 43px 0!important;
  }
  .praceo2{
    padding: 75px 0!important;
  }
  .ceoname h1{
    font-size: 50px;
  }
  .poimgceo1{
    bottom: 0;
  }
  .poimgceo {
    bottom: 0px;
    width: 88%;
  }
 }
 /* media 1200 start ere========================================================================================================= */
 @media screen and (max-width: 1200px) {
  
  .ceoname h1{
    font-size: 40px;
  }
  .praceo{
    padding: 62px 0!important;
    
  }
  .praceo2{
    padding: 54px 0!important;
    
  }
  .ceoroteddive {
    height: 669px;
  }
  .praceo p{
    font-size: 16px!important;
    line-height: normal;
    
  }
  .aboutceo p {
    font-size: 12px;
    line-height: normal;
  }
  .weblink h5{
    font-size: 14px!important;

  }
  /* .allweb li a img {
    width: 37px;
  } */
  .blogimginfo h1{
    font-size: 30px;
  }
  .ceoroteddive {
    width: 82%;
    clip-path: polygon(100% 0, 40% 0, 55% 29%, 0 122%, 100% 100%);
  }
  .poimgceo{
    width: 100%;
  }
  .develpmentser img{
    width: 56%;
    right: -200px;
  }
  .poligon h1{
    width: 4.125rem;
    height: 4.125rem;
    font-size: 24px;
  }
  .line, .line2,.line20{
    width: 71px;
    border-width: 2px;
    height: 80px;
  }
  #team4{
    display: block;
  }
  #team3{
    display: none;
  }
  .propera p{
    font-size: 10px;
    font-weight: bold;
  }
  
  .poligon{
    width: 4.375rem;
    height: 4.375rem;
  }
 .resseveces{
  padding:3rem 0!important;
 }
 .animationdiv {
  height: auto;
 }
 .line4 {
  width: 70px;
 }
 .line5{
  width: 70px;
 }
 .poligon1{
  width: 71px;
  height: 71px;
  border-radius: 15px 15px 15px 0;
 }
 .poligon1 img{
  width: 34px;
 }

 }
 /* media 1200 end ere========================================================================================================= */
 /* media 1024 start ere========================================================================================================= */
 @media screen and (max-width: 1024px) {
  .faqdevelopment h2{
    font-size: 28px;
  }
  .fonsinsevicespag {
    top: 11%;
  }
  .circle12 {
    position: absolute;
    /* border: 40px solid blue; */
    /* border-radius: 50%; */
    top: 0px;
    right: 0px;
    width: 36% !important;
  }
  .poligon h1{
    width: 4.125rem;
    height: 4.125rem;
    font-size: 24px;
  }
  .line, .line2, .line20{
    width: 71px;
    border-width: 2px;
    height: 80px;
  }
  .propera p{
    font-size: 10px;
    font-weight: bold;
  }
  
  .poligon{
    width: 4.375rem;
    height: 4.375rem;
  }
}
 /* media 1024 start end========================================================================================================= */
 /* media 992 start here========================================================================================================= */
 @media screen and (max-width: 992px) {
  .footer-hr{
    width: 100%;
  }
  .robot-img{
    display: none;
  }
  .order2{
    order: 2;
  }
  .order1{
    order: 1;
  }
  .Aero-sapce-banner, .aruomotive, .education, .hightech, .banking, .healthcare, .hospatility, .insurance-banner, .media-banner, .retail-banner, .travel-banner, .utiliti-banner{
    height: auto;
  }
  .bgcoloindbanner, .aautomo{
    position: static;
    width: 100%;
    height: 50vh;
    display: flex;
    align-items: center;

  }
  .industry-banner{
    
    width: 100%;
    height: 50vh;
    display: flex;
    align-items: center;
    background: #211E6D;

  }
 
  .righditearo2{
    width: 50px;
    height: 50px;
    font-size: 30px;
    right: 50px;
  }
  .righditearo{
    width: 50px;
    height: 50px;
    font-size: 30px;
    right: 50px;
  }
  .uiuxsection1{
    background:#211E6D;
    height: 50vh;
  }
  .ceoroteddive,.ceoname h1{
    display: none;
  }
  .praceo{
    width: 100%;
    margin: 0;
  }

  .poimgceo{
    width: 59%;
    right: 0;
  }
  .aboutceo{
    border-top: 3px solid #000;
    border-right: 3px solid #000;
    color: #000;
}
.aboutceo::after{
  background-color: #000;
  height: 2px;
}
.ceosection{
  height: auto;
}
.weblink h5{
  color: #000;
}
.weblink h5 a{
  color: #000;
}
  .praceo p{
    color: #000;
    text-align: left;
    
  }
  .sevi .carousel-indicators{
    width:95%;
    left: 0;
  }
  .counterserveces {
    width: 128px;
    border-radius: 8px;
  }
  .abt-button i{
    display: none;
  }
  .respondive{
    display: none;
  }
  .respondivemobile,.slidermobleresponsive{
    display: block;
  }
  .line, .line2, .line20{
    width: 50px;
    border-width: 2px;
    height: 60px;
  }
  .propera p{
    font-size: 8px;
    font-weight: bold;
  }
  .company-logo{
    margin-top: -290px;
  }
  .grat-company{
    min-height: 450px;
  }
  #whyDwellfox{
    background-image: none;
  }
  .mobileheadingfs {
    text-align: center;
  }
  .all_center_footer {
    text-align: center;
  }
  .home-section1 {
    /* padding-top: 2em; */
    padding-left: 1em;
  }

  .home-section1 > h1 {
    font-weight: 700;
    font-size: 32px;
    line-height: 110%;
  }

  .home-section1 > h3 {
    font-weight: 100;
    font-size: 32px;
    line-height: 110%;
  }

  .home-section1 > p {
    font-weight: 200;
    font-size: 14px;
    line-height: 33px;
    padding-right: 1em;
    padding-top: 34px;
  }

  .mainbtn {
    padding-bottom: 15px;
  }

  
  .mobileparafs {
    text-align: center;
  }
  .capablesection {
    background: linear-gradient(
      152.97deg,
      rgba(238, 238, 238, 0.23) 0%,
      rgba(238, 238, 238, 0.135) 100%
    );
    backdrop-filter: blur(0px);
    border-radius: 0px;
    padding-left: 0px;
    padding-right: 0px;
    margin-left: 0px;
    margin-right: 0px;
  }

  .contact-banner-web-section {
    background-color: black;
    height: 86px;
  }
  .aboutsection3-part2 {
    height: 100%;
    position: static;
  }

  .fontsizeexplor12 {
    font-size: 24px;
    /* line-height: 1.5; */
  }

  .fontsizeexplor123 {
    font-size: 24px;
    /* line-height: 1.5; */
  }

  .btnexplor12 {
    font-size: 18px;
    /* line-height: 1.5; */
    border-radius: 4px;
    padding: 8px 16px;
  }

  .skilled {
    position: static;
  }
  .banner {
    padding: 50px 0px 10px 0px;
    padding-bottom: 35px;
  }

  .carousel-caption h1 {
    /* margin-bottom: 30%; */
    font-size: 28px !important;
    line-height: 1.5;
    font-weight: 500;
    text-transform: uppercase;
  }

  .carousel-caption {
    bottom: 0rem;
  }

  .banner > h1 {
    font-weight: 600;
    font-size: 24px;
    line-height: 73px;
  }

  .banner > p {
    font-weight: 400;
    font-size: 16px;
    line-height: 189%;
  }

  .clients-section {
    padding-left: 20px;
  }

  .healthcare-technology {
    height: auto;
  }

  .technology-div h5 {
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
  }

  .custom-heading {
    font-weight: 500 !important;
    font-size: 32px !important;
    line-height: 40px !important;
  }

  .custom-paragraph-heading {
    font-weight: 500 !important;
    font-size: 20px !important;
    line-height: 40px !important;
  }

  .cms-heading {
    font-weight: 500 !important;
    font-size: 32px !important;
    line-height: 40px !important;
  }

  .cms-paragraph-heading {
    font-weight: 500 !important;
    font-size: 20px !important;
    line-height: 40px !important;
  }

  .cms-development-section2 h2 {
    font-weight: 600;
    font-size: 28px !important;
    line-height: 130%;
  }

  .section3-heading {
    font-weight: 600 !important;
    font-size: 38px !important;
    line-height: 130% !important;
  }

  .section6-heading {
    font-weight: 600 !important;
    font-size: 30px !important;
    line-height: 40px !important;
  }

  .cms-website-section h2 {
    font-weight: 600 !important;
    font-size: 20px !important;
    line-height: 20px !important;
  }

  .digital-transformation-section span {
    font-weight: 600 !important;
    font-size: 20px !important;
    line-height: 30px !important;
  }

  .cms-website-section p {
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 24px !important;
  }

  .cms-specialize-heading {
    font-weight: 600 !important;
    font-size: 30px !important;
    line-height: 30px !important;
    color: #ffffff !important;
  }

  .cms-ul-li-section ul li {
    font-weight: 600 !important;
    font-size: 18px !important;
    line-height: 180% !important;
    color: #ffffff !important;
  }

  .section5-heading {
    font-weight: 700;
    font-size: 28px !important;
    line-height: 30px !important;
    text-align: center;
    color: #211e6d;
  }

  .section4-heading {
    font-weight: 700;
    font-size: 28px !important;
    line-height: 30px !important;
    text-align: center;
    color: #000000;
  }

  .gallery-heading-section {
    font-weight: 600 !important;
    font-size: 30px !important;
    line-height: 30px !important;
    color: #ffffff;
  }

  .development-custom-section {
    padding-top: 110px !important;
  }

  .custom-development-section3 h2 {
    font-weight: 600 !important;
    font-size: 28px !important;
    line-height: 50px !important;
    padding-bottom: 20px !important;
  }

  .frameworksection {
    margin-left: 0px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .mainhadingcustom {
    position: absolute !important;
    left: 50% !important;
    top: 6% !important;
    transform: translateX(-50%) !important;
    z-index: 1 !important;
    width: 90% !important;
  }

  .custom-development-service {
    width: 100% !important;
    height: 60vh !important;
  }

  .slick-next {
    display: none !important;
  }

  .slick-prev {
    display: none !important;
  }

  .web-development-heading {
    font-weight: 400 !important;
    font-size: 18px !important;
    line-height: 189% !important;
    color: #161616 !important;
  }

  .case-study-anchor-section a {
    padding: 10px !important;
  }
  .clientmain .column {
    -ms-flex: 20%;
    flex: 20%;
    max-width: 25%;
  }
  .mobile_color {
    display: block;
  }

  .benifit {
    display: none;
  }

  .button-size span {
    font-size: 1rem;
  }

  .industryContent {
    order: 2;
  }

  .industryImage {
    order: 1;
  }
  .services_wordpress {
    flex-direction: column;
    align-items: center;
  }

  .healthcare-technology-section {
    font-weight: 600;
    font-size: 24px;
    line-height: 40px;
  }

  .industryContent {
    order: 2;
  }

  .industryImage {
    order: 1;
  }

  .web-develop-overview {
    width: 100% !important;
    height: 100% !important;
  }

  .web-development {
    width: 100%;
    height: 40vh !important;
  }

  .insights-development {
    width: 100%;
    height: 40vh !important;
  }

  .why-should-img {
    width: 67% !important;
    margin-top: 25px !important;
    text-align: center !important;
  }

  .differentiators-img {
    width: 67% !important;
    margin-top: 25px !important;
    text-align: center !important;
  }

  .gallery-heading-section {
    font-weight: 600 !important;
    font-size: 30px !important;
    line-height: 30px !important;
    color: #ffffff;
  }

  .custom-development-service {
    width: 100% !important;
    height: 50vh !important;
  }

  .circle12 {
    position: absolute;
    /* border: 40px solid blue; */
    /* border-radius: 50%; */
    top: 0px;
    right: 0px;
    width: 21% !important;
  }

  .circle123 {
    position: absolute;
    /* border: 40px solid blue; */
    /* border-radius: 50%; */
  }
  .mobile_color {
    display: block;
  }

  .benifit {
    display: none;
  }

  .mobilealigncenter {
    text-align: center;
  }

  .happymain {
    position: static !important;
    text-align: center;
  }

  .industry-banner-section {
    padding-left: 0px !important;
  }
  .paratag2 {
    color: #fff;
    text-align: center;
  }
  .paratag3 {
    text-align: center;
  }
  .paratag1 {
    color: black;
    text-align: center;
  }
  .why-dwellfox {
    padding: 1em;
  }

  .why-dwellfox > h2 {
    margin-top: 0;
    text-align: center;
    font-weight: 600;
    font-size: 36px;
    line-height: 58px;
    color: #211e6d;
    padding-bottom: 6px;
  }

  .why-dwellfox > p {
    text-align: center;
    font-weight: 300;
    font-size: 15px;
    line-height: 27px;
    /* padding-bottom: 3em; */
  }

  .card-body > h4 {
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    color: #211e6d;
    /* padding-top: 24px; */
  }

  .card-body > span {
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    padding-bottom: 12px;
  }

  .card-body > p {
    font-weight: 236;
    font-size: 13px;
    line-height: 160%;
    padding-top: 12px;
  }

  .why-dwellfox-1 {
    padding: 1em;
  }

  .abt-button {
    border-radius: 8px;
    padding: 12px 54px;
    color: #fff;
    background-color: #211e6d;
    text-decoration: none;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
  }
  .abt-button i{
    animation: arrowblonk .3s linear infinite;
  }
  @keyframes arrowblonk {
    0%{
      visibility: hidden;
    }
    100%{
      visibility: visible;
    }
    
  }
  .why-dwellfox {
    text-align: center;
  }

  .abt-button:hover {
    color: white;
  }

  .cms-website-section h2 {
    font-weight: 600;
    font-size: 28px;
    line-height: 25px;
    color: #000000;
    text-align: center;
  }

  .cms-website-section p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #000000;
  }

  .digital-transformation-section span {
    font-weight: 600;
    font-size: 28px;
    line-height: 40px;
  }
  .icon-bar{
    display: flex;
  }
  .icon-bar a {
    margin-left: 10px;
  }
    .ecommerce-development-section h2 {
      color: #000;
      text-align: center;
    }
  
 
    .ecommerce-development-section p {
      text-align: center;
    }
  
  .all_center-exp {
    text-align: center;
  }
  .all_center-img {
    text-align: center;
  }
  .solution-head {
    text-align: center;
  }
  .insight-main-head {
    text-align: center;
  }
  .indus-head {
    text-align: center;
  }
  .gallery-why-choose {
    text-align: center;
  }
  .exp_now {
    text-align: center;
   
  }
  .overviewheading {
    font-weight: 400;
    font-size: 18px;
  }
  .headthheading {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 30px;
  }
  .ai-line {
    visibility: hidden;
  }
  .ai-resp {
    margin-left: 45px;
  
  }
  .staf-IT-Skills {
    text-align: center;
  }
  .posemobile{
    display: none;
  }
  .poligon1 {
    margin: auto;
  }
  .mocenter{
    text-align: left;
  }
  .mainseves{
    background-image: linear-gradient(#211E6D,#211E6D);
    height: 50vh!important;
  }
  

}
 /* media 992 end here */


 /* media 768 start here =====================================================================================================*/
 @media screen and (max-width: 768px) {
  .virticleline{
    border: none;
  }
  .blogtabs1 li{
    padding: 3px;
  }
  .blogtabs1 a{
    font-size: 10px;
  }
  .carjobs{
    width: 100%;
  }
  .ceolinkwebside{
    flex-direction: column;
  }
  .ceotwo{
    display: none;
  }
  .ceo1{
    display: block;
  }
  .poimgceo {
    top: 134px;
  }
  .weblink h5{
    font-size: 15px;
  } 
  .allweb li a img{
    width: 30px;
  }
  .allweb{
    padding-left: 0;
   
  }
  .inquerybotm{
    height: auto;
  }
  .industriesverce{
    background:#211e6d;
  }
  .mobprohome1{
    display: none;
  }
  .mobprohome{
    display: block;
  }
  .propera p {
    font-size: 11px;
  }
  .primg img{
    width: 66%!important;
  }
  .line,.line2  {
    display: none;
  }
  .line,.line20  {
    display: none;
  }
 
  .develpmentser img{
    position: static;
    margin-bottom: 20px;
    width: 100%;
  }
  .faqdevelopment{
    padding-left: 0;
    text-align: center;
  }
  .realitydfx .container{
    position: static;
  }
  .realitydfx .deimgresponsv{
    text-align: center!important;
    margin-bottom: 20px;
  }
  .realitydfx .container .row{
    width: 100%;
    margin: 0;
  }
  .resdevm{
    display: none;
  }
  .resdevm1{
    display: block;
  }

  .img-size{
    width: 30%;
  }
  .mo{
    display: block;
  }
  .sevesliderpage{
    position: static;
    width: 100%;
    margin-bottom: 20PX;
  }
  .counterserveces{
    width: 100%!important;
  }
  .mob{
    display: none;
  }
  h2{
    line-height:normal;
  }
  .Operation{
    display: none;
  }
  .blogdetailspage h2{
    font-size: 20px!important;
  }
  .blogdetailspage h3{
    font-size: 24px!important;
  }
  /* .mainvideoslider h1{
    font-size: 28px;
  } */
  /* .mainvideoslider {
  
    background-size: cover;
   
} */
  .healthcare-technology1 {
    height: auto;
  }
  .previous{
    padding: 8px!important;
  }
  .loadmorebtn {
    margin-right: 22px;
  }
  .pro_ite {
    margin-left: 50px;
  }
  .contact-main-head {
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
  }
  .web-resp {
    text-align: center;
  }
   .web-hrline {
    margin-left: 16%;
  }
  .web-hrline-second {
    width: 100%;
  }
  .web-hrline-third {
    margin-left: 20%;
  }
  .web-hrline-fourth {
    margin-left: 20%;
  }
  .sales_hrline {
    margin-left: 20%;
  }
  .card-content p {
    text-align: center;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
  }
  .cparagrap {
    text-align: center;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
  }
  .img_center {
    text-align: center;
  }
  .card-p1 {
    font-style: normal;
    font-size: 15px;
    font-weight: 150;
  }
  .no-gutters {
    text-align: center;
  }
 
  .no-img{
    width: 77px;
  }
  .card-content-section p {
    font-weight: 400;
    font-size: 15px;
    color: #667085;
 
}
.service-main-head {
  font-size: 16px;
  color: #fff;
  line-height: 180%;
}
.career-main-head {
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
}

  .career-main-head {
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
  }

  .location-heading {
    font-weight: 600;
    color: #25373f;
    font-size: 33px;
    text-align: center;
  }
  .capablesection {
    background: linear-gradient(
      152.97deg,
      rgba(238, 238, 238, 0.23) 0%,
      rgba(238, 238, 238, 0.135) 100%
    );
    backdrop-filter: blur(0px);
    /* border-radius: 0px; */
    padding-left: 0px;
    padding-right: 0px;
    margin-left: 0px;
    margin-right: 0px;
  }

  .mainhadinghome h1 {
    font-size: 1rem !important;
  }

  .button-size a {
    width: 80%;
  }

  .dfxbanner {
    padding: 1rem;
  }

  .contact-banner-web-section {
    background-color: black !important;
    height: 110px !important;
  }

  .contact-heading {
    font-size: 22px !important;
    line-height: 1.5 !important;
  }
  .industry-button {
    flex-direction: column;
  }
 

  .clients-section {
    padding-left: 50px;
  }

  .healthcare-technology {
    height: auto;
  }

  .technology-div h5 {
    font-weight: 600;
    font-size: 14px;
    line-height: 28px;
  }
  .buttonSection > p {
    margin-top: 1rem !important;
    padding-left: 0px;
  }

  .cms-website-section h2 {
    font-weight: 600;
    font-size: 28px;
    line-height: 30px;
    color: #000000;
    text-align: center;
  }

  .cms-website-section p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #000000;
  }

  .digital-transformation-section span {
    font-weight: 600;
    font-size: 28px;
    line-height: 30px;
  }
  
  .why-dwellfox > h2,.Advantage > h2{
    font-size: 28px!important;
  }
  h2{
   
    font-size: 28px;
  }
  .capabilitessection1 {
    margin-left: 0px;
    width: 100%;
  }

  .custom-heading {
    font-weight: 500 !important;
    font-size: 32px !important;
    line-height: 40px !important;
  }

  .custom-paragraph-heading {
    font-weight: 500 !important;
    font-size: 20px !important;
    line-height: 40px !important;
  }

  .development-custom-section {
    padding-top: 110px !important;
  }

  .custom-development-section3 h2 {
    font-weight: 600 !important;
    font-size: 28px !important;
    line-height: 50px !important;
    padding-bottom: 20px !important;
  }

  .custom-development-section {
    background: #f1f5f9;
    box-shadow: 0px 8px 16px rgb(0 0 0 / 16%);
    margin-top: -150px;
    left: 3% !important;
    position: absolute;
  }

  .development-description {
    padding-top: 295px !important;
  }

  .mainhadingcustom {
    position: absolute !important;
    left: 43% !important;
    top: 20% !important;
    transform: translateX(-50%) !important;
    z-index: 1 !important;
    width: 78% !important;
  }
  .capabilitessection2 {
    margin-right: 0px;
    width: 100%;
  }

  .frameworksection {
    margin-left: 0px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .mobileheadingfs {
    font-size: 24px !important;
  }
}
 /* media 768 end here */

/* media 576 start here */
@media screen and (max-width: 576px){
  .arulpadding {
    padding-left: 5%;
  }
  .bgcoloindbanner p, .aautomo p, .industry-banner p{
    font-size: 24px;
  }
  .jopformdetail{
    width: 100%;
  }
  .righditearo2, .righditearo{
    display: none;
  }
}
/* media 576  end here */

@media screen and (max-width: 425px){
  .policy-heading{
    font-size: 34px;
  }
}
 
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  appearance: textfield;
}
  